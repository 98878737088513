import Icon from "components/Icon";
import React, { Fragment } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  Alert,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label
} from "reactstrap";
import styled from "styled-components";

const KeyInput = styled(Input)`

`;

class StreamURLandKey extends React.Component {
  state = {
    copied: false,
    keyHidden: false,
  };

  onCopy = (what = "copied") => () => {
    this.setState({
      [what]: true,
    });
  };

  toggle = () => {
    this.setState({
      keyHidden: !this.state.keyHidden,
    });
  };

  render() {
    const { stream_key, title } = this.props;
    const { copied, keyCopied, keyHidden, urlCopied } = this.state;

    const url = "rtmp://go.eventlive.pro/live";

    const rtmpUrl = `${url}/${stream_key}`;

    return (
      <Fragment>
        <FormGroup>
          <Label>RTMP Server</Label>
          <InputGroup>
            <KeyInput disabled value={url} />
            <InputGroupAddon addonType="append">
              <CopyToClipboard text={url} onCopy={this.onCopy()}>
                <Button>
                  {!copied && (
                    <Fragment>
                      <Icon name="copy" />
                      <span className="d-sm-inline d-none">Copy</span>
                    </Fragment>
                  )}
                  {copied && (
                    <Fragment>
                      <Icon name="check" />
                      <span className="d-sm-inline d-none">Copied!</span>
                    </Fragment>
                  )}
                </Button>
              </CopyToClipboard>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <Label>Your Event's Private Stream Key</Label>
          <InputGroup>
            <KeyInput disabled value={keyHidden ? "********" : stream_key} />
            <InputGroupAddon addonType="append">
              <Button onClick={this.toggle}>
                {!keyHidden && (
                  <>
                    <Icon name="eye-slash" />
                    <span className="d-sm-inline d-none">Hide</span>
                  </>
                )}
                {keyHidden && (
                  <>
                    <Icon name="eye" />
                    <span className="d-sm-inline d-none">Reveal</span>
                  </>
                )}
              </Button>
              <CopyToClipboard
                text={stream_key}
                onCopy={this.onCopy("keyCopied")}
              >
                <Button>
                  {!keyCopied && (
                    <>
                      <Icon name="copy" />
                      <span className="d-sm-inline d-none">Copy</span>
                    </>
                  )}
                  {keyCopied && (
                    <>
                      <Icon name="check" />
                      <span className="d-sm-inline d-none">Copied!</span>
                    </>
                  )}
                </Button>
              </CopyToClipboard>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <Alert color="warning">
          Every event and test has a unique key. This stream key is valid for "{title}"
          event only.
        </Alert>

        <FormGroup>
          <Label>Full RTMP URL (for GoPro)</Label>
          <InputGroup>
            <KeyInput disabled value={keyHidden ? "********" : rtmpUrl} />
            <InputGroupAddon addonType="append">
              <Button onClick={this.toggle}>
                {!keyHidden && (
                  <>
                    <Icon name="eye-slash" />
                    <span className="d-sm-inline d-none">Hide</span>
                  </>
                )}
                {keyHidden && (
                  <>
                    <Icon name="eye" />
                    <span className="d-sm-inline d-none">Reveal</span>
                  </>
                )}
              </Button>
              <CopyToClipboard text={rtmpUrl} onCopy={this.onCopy("urlCopied")}>
                <Button>
                  {!urlCopied && (
                    <>
                      <Icon name="copy" />
                      <span className="d-sm-inline d-none">Copy</span>
                    </>
                  )}
                  {urlCopied && (
                    <>
                      <Icon name="check" />
                      <span className="d-sm-inline d-none">Copied!</span>
                    </>
                  )}
                </Button>
              </CopyToClipboard>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Fragment>
    );
  }
}

export default StreamURLandKey;
