import React from 'react';
import { Badge } from 'reactstrap';


const statuses = {
  live: (<Badge color="info">LIVE NOW</Badge>),
  upcoming: (<Badge color="success">Upcoming</Badge>),
  pending: (<Badge color="success">Starting</Badge>),
  paused: (<Badge color="warning">Paused</Badge>),
  past: (<Badge color="dark">Complete</Badge>),
};

export default ({ status }) => {

  if (status in statuses) {
    return statuses[status];
  }
  return '';
}
