import { Auth } from "aws-amplify";
import React from "react";
import { Alert, Button } from "reactstrap";

import Header from "../../Authenticated/Header";

export default class ForgotPassword extends React.Component {
  state = {
    fields: {
      email: "",
    },
    canSubmit: true,
    errorMessage: "",
  };

  componentDidMount = async () => {
    // attempt to load auth current user and pass email
    try {
      const user = await Auth.currentAuthenticatedUser();
      const { email } = user.attributes;
      if (email) {
        this.setState({
          fields: {
            ...this.state.fields,
            email,
          },
        });
      }
    } catch (err) {}
  };

  changeField = (field, value) =>
    this.setState({
      fields: { ...this.state.fields, [field]: value },
    });

  displayError = () => {
    if (this.state.errorMessage.length > 0) {
      return <Alert color="danger">{this.state.errorMessage}</Alert>;
    }

    return "";
  };

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ canSubmit: false });
      const { email } = this.state.fields;
      window.localStorage.setItem("email", email);
      await Auth.forgotPassword(email);
      this.props.history.push("/check_your_email");
    } catch (e) {
      console.log(e);
      if (e.code === "UserNotFoundException") {
        this.setState({
          errorMessage:
            "We couldn't find any user with this email. It is possible your email started with an uppercase letter?",
        });
      } else if (e.code === "InvalidParameterException") {
        this.setState({
          errorMessage: "This user does not have a verified email account.",
        });
      } else {
        this.setState({ errorMessage: e.message });
      }
    } finally {
      this.setState({ canSubmit: true });
    }
  };

  render = () => (
    <div className="unauthenticated p-3">
      <Header />
      <form className="form-signin text-center" onSubmit={this.onSubmit}>
        <h1 className="h3 mb-3 font-weight-normal">Password Restore</h1>
        {this.displayError()}
        <label className="sr-only">Email address</label>
        <input
          type="email"
          className="form-control"
          placeholder="Email address"
          required
          autoFocus
          value={this.state.fields.email}
          onChange={(e) => this.changeField("email", e.target.value)}
          autoCorrect="off"
          autoCapitalize="none"
        />

        <button
          className="btn btn-lg btn-primary btn-block my-3"
          type="submit"
          disabled={!this.state.canSubmit}
        >
          Send email
        </button>

        {!this.state.fields.email && (
          <div className="text-left">
            <Button color="link" onClick={() => this.props.history.push("/")}>
              &larr; Back to log in
            </Button>
          </div>
        )}
      </form>
    </div>
  );
}
