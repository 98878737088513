import { Storage } from "aws-amplify";
import Icon from "components/Icon";
import React, { Fragment } from "react";
import Dropzone from "react-dropzone";
import { Button, Progress } from "reactstrap";
import styled from "styled-components";

const getColor = (props) => {
  if (props.isDragReject) {
    return "#c66";
  }
  if (props.isDragActive) {
    return "#6c6";
  }
  return "#CCC";
};

const Container = styled.div`
  min-height: 400px;
  border: 2px dashed ${(props) => getColor(props)};
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  margin: 0 auto;
  color: #ccc;
  text-align: center;
`;

const Accepts = styled.small`
  display: block;
  margin-top: 5%;
  margin-bottom: 10%;
`;

const FileDropHeader = styled.h1`
  margin-top: 50px;
`;

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

class Cover extends React.Component {
  state = {};

  onDrop = async (file) => {
    const first = file[0];

    this.setState({
      preview: URL.createObjectURL(first),
      uploading: true,
    });

    if (!first) return;
    const key = this.props.makeKey(first);
    try {
      const res = await Storage.put(key, first, {
        bucket: this.props.bucket,
        region: 'us-east-1',
        progressCallback: (progress) => {
          const percent = (progress.loaded / progress.total) * 100;
          this.setState({ progress: percent });
        },
      });
      this.setState({
        uploading: false,
        preview: null,
      });
      if (this.props.onDone) {
          this.props.onDone();
      }
    } catch (err) {
      this.setState({
        uploading: false,
        err,
      });
      console.error(err);
    }
  };

  renderExplanation = (isDragActive) => (
    <Fragment>
      {isDragActive ? (
        <FileDropHeader>Drop file here...</FileDropHeader>
      ) : (
        <FileDropHeader>Drop image here</FileDropHeader>
      )}
      <p>Or...</p>
      <Button color="primary">Select file</Button>

      <Accepts>Accepts png or jpeg file under 50MB</Accepts>
    </Fragment>
  );

  render() {
    const {  uploading, preview, progress } = this.state;
    return (
        <Dropzone
          onDrop={this.onDrop}
          accept={["image/jpg", "image/jpeg", "image/png"]}
          multiple={false}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
            return (
              <Container
                isDragActive={isDragActive}
                isDragReject={isDragReject}
                {...getRootProps()}
              >
                <input {...getInputProps()} />

                {!uploading && this.renderExplanation(isDragActive)}

                {uploading && (
                  <div>
                    <FileDropHeader>
                      Please wait, uploading image{" "}
                      <Icon name="spinner fa-spin" />
                    </FileDropHeader>
                    <div style={{ margin: 10 }}>
                      <Progress animated value={progress} />
                    </div>
                  </div>
                )}

                {preview && (
                  <div style={thumb}>
                    <div style={thumbInner}>
                      <img src={preview} style={img} alt="" />
                    </div>
                  </div>
                )}
              </Container>
            );
          }}
        </Dropzone>
    
    );
  }
}

export default Cover;
