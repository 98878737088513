import './index.css';
import React, { Fragment } from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import Header from './Header'
import Dashboard from './Dashboard'
import CreateEvent from './CreateEvent'
import EditEvent from './EditEvent'
import Settings from './Settings'
import VerifyEmail from 'Application/Unauthenticated/VerifyEmail'

export default withRouter(({ location, hideNav = false }) => (
  <Fragment>
    <Header
      emailJustVerified={location.search === "?email_verified"}
      hideNav={hideNav}
    />

    <div className={`container authenticated-page-container`} style={hideNav ? { marginTop: 10 } : {}}>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/verify_email/:code" component={VerifyEmail} />
        <Route exact path="/create_event" component={CreateEvent} />
        <Route exact path="/event/:slug/:tab?" component={EditEvent} />
        <Route exact path="/settings/:tab?" component={Settings} />
        <Redirect to="/" />
      </Switch>
    </div>
  </Fragment>
));
