import { API, Auth, graphqlOperation } from "aws-amplify";
import Icon from "components/Icon";
import React from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink as RRNavLink, Route, Switch } from "react-router-dom";
import { Button, Col, Nav, NavLink, Row } from "reactstrap";

import Broadcast from "./tabs/Broadcast";
import Page from "./tabs/Page";
import Profile from "./tabs/Profile";
import Purchase from "./tabs/Purchase";

class Settings extends React.Component {
  state = {
    loading: false,
  };

  signOut = () =>
    Auth.signOut()
      .then(() => (window.location = "/"))
      .catch((err) => console.error(err));

  render() {
    const { match } = this.props;

    if (this.state.loading) {
      return (
        <div class="d-flex justify-content-center">
          <i className="fa fa-spin fa-spinner"></i>
        </div>
      );
    }


    return (
      <Row>
        <Col sm={3} xs={12}>
          <Helmet>
            <title>Profile</title>
          </Helmet>
          <Nav vertical className="mb-3">
            {/* <NavLink to={`/settings/broadcast`} tag={RRNavLink}>
              <Icon name="video-camera" />Broadcast Settings
            </NavLink> */}
            <NavLink to={`/settings/purchase`} tag={RRNavLink}>
              <Icon name="usd" />
              Get Credits
            </NavLink>

            <NavLink to={`/settings/profile`} tag={RRNavLink}>
              <Icon name="user" />
              Profile
            </NavLink>

            <NavLink to={`/forgot_password`} tag={RRNavLink}>
              <Icon name="key" />
              Reset Password
            </NavLink>

            <NavLink
              to={`/settings/profile`}
              tag={RRNavLink}
              onClick={this.signOut}
            >
              <Icon name="power-off" />
              Sign Out
            </NavLink>
          </Nav>
        </Col>
        <Col sm={9} xs={12}>
          <Switch>
            <Route 
              path={`${match.path}/broadcast`}
              render={() => <Broadcast />}
            />
            <Route
              path={`${match.path}/purchase`}
              render={() => <Purchase />}
            />
            <Route path={`${match.path}/page`} render={() => <Page />} />
            <Route path={`${match.path}/profile`} render={() => <Profile />} />
          </Switch>
        </Col>
      </Row>
    );
  }
}

export default Settings;
