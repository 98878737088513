// import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap-override.scss';
import 'react-datepicker/dist/react-datepicker.css'
import 'font-awesome/css/font-awesome.min.css';

import React from 'react'
import ReactDOM from 'react-dom'
import Application from './Application'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify'

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    mandatorySignIn: false
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_REGION,
  aws_appsync_authenticationType: 'AWS_IAM',
  Storage: {
    customPrefix: {
      public: '',
    }
  },
  graphql_headers: async () => {
    try {
      const token = (await Auth.currentSession()).accessToken.jwtToken;

      return { JWT: token }
    }
    catch (e) {
      console.error(e);
      return {};
    }
  }
})


const ApplicationWrapper = () => (
  <BrowserRouter>
    <Application />
  </BrowserRouter>
)

ReactDOM.render(
  <ApplicationWrapper />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
