import FieldText from "components/FieldText";
import FormSubHeader from "components/FormSubHeader";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Card, CardBody, CardText, Form } from "reactstrap";
import { downloadGuestbook } from "utils";
import * as Yup from "yup";

import API, { graphqlOperation } from "@aws-amplify/api";

const GuestbookMessageSchema = Yup.object().shape({
  message: Yup.string().required("Required"),
});


const commentsQuery = (broadcaster, slug) =>
  graphqlOperation(
    `
      query event($broadcaster: String!, $slug: String!) {
        event(broadcaster: $broadcaster, slug: $slug) {  
          guests {
            id
            name 
            email
          }
          guestsCount
          guestbook_messages {
            id
            message_datetime_utc
            message
            pinned
            guest {
                id
                name
                email
            }
          }
        }
      }
    `,
    { broadcaster, slug }
  );


const deleteCommentMutation = `
  mutation DeleteComment($event_id: String!, $id: String!) {
    deleteGuestbookComment(
      event_id: $event_id,
      id: $id
    )
  }
`;

const Guestbook = ({ event }) => {
  const { slug, broadcaster, id: event_id } = event;

  const [eventWithGuests, setEventWithGuests] = useState(event);
  const [justDeleted, setJustDeleted] = useState(false);

  const fetchGuestbook = async () => {
    const { data } = await API.graphql(commentsQuery(broadcaster, slug));
    setEventWithGuests({ ...event, ...data.event });
  };

  useEffect(() => {
    fetchGuestbook();
  }, []);

  const {
    guestbook_messages: messages = [],
  } = eventWithGuests;



  const deleteComment = async (id) => {
    if (!window.confirm("Are you sure to delete a comment?")) { return; }
    try {
      const { errors, data: result } = await API.graphql(
        graphqlOperation(deleteCommentMutation, { event_id: event.id, id })
      );
      if (result) {
        setJustDeleted(true);
      }
      await fetchGuestbook();
    } catch (err) {
      console.error(err);
    }
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const { message } = values;
    try {
      await API.graphql(
        graphqlOperation(
          `
          mutation sendPinnedMessage($event_id: String!, $message: String!) {
            sendPinnedMessage(
              event_id: $event_id,
              message: $message
            ) {
              id
              event_id
              message
              message_datetime_utc
              pinned
              guest {
                id
                name
              }
            }
          }
          `,
          {
            event_id,
            guest_id: event_id,
            message,
            message_datetime_utc: new Date().toISOString(),
          }
        )
      );
      await fetchGuestbook();
      setSubmitting(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      {justDeleted && <Alert color="success">Comment has been deleted</Alert>}

      <FormSubHeader>Guestbook Comments</FormSubHeader>
      <Helmet>
        <title>Guestbook</title>
      </Helmet>

      <Button onClick={() => downloadGuestbook(broadcaster, slug)} color="link" style={{ padding: 0 }}>
        <i className="icon fa fa-download pr-2" aria-hidden="true"></i>
        Download and Save the Guestbook
      </Button>

      <Formik
        initialValues={{ message: "" }}
        validationSchema={GuestbookMessageSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form className="my-5">
            <Field
              label="Pinned Message"
              name="message"
              component={FieldText}
              type="textarea"
              placeholder="Leave a message for all the guests. It will be displayed on top of the guestbook."
            />
            <Button
              color="primary"
              className=""
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
            >
              Post Message
            </Button>
          </Form>
        )}
      </Formik>

      {!messages.length && <p>No comments left so far.</p>}
      {messages.map((item) => (
        <Card key={item.id} className="message mb-2">
          <CardBody className="position-relative">
            <CardText>
              {item.pinned && (
                <i
                  className="icon fa fa-thumb-tack pr-2"
                  aria-hidden="true"
                ></i>
              )}
              {item.message} <br />
              {item.guest && item.guest.name} {item.guest && item.guest.email}
              <Button
                color="link position-absolute"
                style={{ top: "1rem", right: 0 }}
                onClick={() => {
                  deleteComment(item.id);
                }}
              >
                <i className="icon fa fa-trash pr-2" aria-hidden="true"></i>
              </Button>
            </CardText>
          </CardBody>
        </Card>
      ))}
    </div>
  );
};

export default Guestbook;
