import './index.css'

import RedirectWithState from  'components/RedirectWithState';
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Header from '../Authenticated/Header'
import Register from './Register'
import RegisterFD from "./RegisterFD";
import RegisterWedding from './RegisterWedding'
import Signin from './Signin'

export default () => (
  <div className="unauthenticated p-3">
    <Header />

    <Switch>
      <Route exact path="/signup" component={Register} />
      <Route exact path="/signupfd" component={RegisterFD} />
      <Route exact path="/signup_wedding" component={RegisterWedding} />
      
      <Route exact path="/" component={Signin} />
      <RedirectWithState to="/" />
    </Switch>
  </div>
);
