import { API, graphqlOperation } from "aws-amplify";
import DatePicker from "components/DatePicker";
import FieldText from "components/FieldText";
import FormHeader from "components/FormHeader";
import FormPrivacySettings from "components/FormPrivacySettings";
import FormSubHeader from "components/FormSubHeader";
import { Field, Formik } from "formik";
import moment from "moment-timezone";
import React from "react";
import {Helmet} from "react-helmet"
import { Redirect } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap";
import * as Yup from "yup";

const createEventMutation = `
  mutation CreateEvent($details: EventInput!) {
    createEvent(
      details: $details
    ) {
      id
      slug
      title
      event_datetime_utc
      event_window_start_datetime_utc
      event_window_end_datetime_utc
      event_timezone
      event_privacy
      event_location
      guestbook_enabled
      download_enabled
      download_pin_enabled
      download_pin
      event_password
      alias
    }
  }
`;

const CreateEventSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  event_datetime: Yup.date().required("Required"),
});

class CreateEvent extends React.Component {
  state = {
    initialState: {
      title: "",
      event_location: "",
      event_timezone: moment.tz.guess(),
      guestbook_enabled: true,
      event_privacy: "unlisted",
      event_datetime: null,
      download_enabled: false
    },
    errorMessage: "",
  };

  componentWillMount = async () => {
    const {
      data: { broadcaster },
    } = await API.graphql(
      graphqlOperation(`query { broadcaster: broadcasterProfile { username } }`)
    );

    const { username } = broadcaster;
    this.setState({ username });
  };

  displayError = () => {
    if (this.state.errorMessage.length > 0) {
      return <Alert color="danger">{this.state.errorMessage}</Alert>;
    }

    return "";
  };

  onSubmit = async (values, { setSubmitting }) => {
    const {
      title,
      event_datetime,
      event_timezone,
      event_privacy,
      event_location,
      guestbook_enabled,
      event_password,
      download_enabled,
    } = values;

    const event_date = moment(event_datetime).format('YYYY-MM-DD');
    const event_time = moment(event_datetime).format('HH:mm');

    const date = moment.tz(`${event_date} ${event_time}`, event_timezone);

    const event_datetime_utc = date.utc().format();

    const {
      errors,
      data: { createEvent },
    } = await API.graphql(
      graphqlOperation(createEventMutation, {
        details: {
          title,
          event_date,
          event_time,
          event_datetime_utc,
          event_timezone,
          event_privacy,
          event_location,
          guestbook_enabled,
          event_password,
          download_enabled,
        },
      })
    );

    console.log(errors, createEvent);

    if (!errors) {
      this.setState({ done: true, slug: createEvent.slug });
    } else {
      this.setState({
        errorMessage:
          "Could not create event. Please double check and try again.",
      });
    }

    setSubmitting(false);
  };

  render() {
    if (this.state.done) {
      return <Redirect to={`/event/${this.state.slug}`} />;
    }

    return (
      <Col sm={8} xs={12}>
        <Helmet>
          <title>Create Event</title>
        </Helmet>
        <Formik
          initialValues={this.state.initialState}
          validationSchema={CreateEventSchema}
          onSubmit={this.onSubmit}
        >
          {({
            values,
            submitForm,
            isSubmitting,
            errors,
            touched,
            isValid,
            setFieldTouched,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => (
            <Form>
              <FormHeader>New Event</FormHeader>
              <FormGroup>
                <Input
                  autoFocus
                  name="title"
                  placeholder="Event Title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  invalid={!!(touched.title && errors.title)}
                />
                {errors.title && touched.title && (
                  <FormFeedback>{errors.title}</FormFeedback>
                )}
              </FormGroup>

              <FormGroup className="mb-5">
                <Field
                  name="event_location"
                  component={FieldText}
                  placeholder="EventEvent Location (e.g. The Metropolitan Museum of Art, New York City"
                />
              </FormGroup>

              <FormSubHeader>Event Date and Time</FormSubHeader>

              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <DatePicker
                      selected={values.event_datetime}
                      onChange={(value) => {
                        setFieldValue("event_datetime", value);
                      }}
                      showTimeSelect
                      timeFormat="h:mm aa"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      placeholderText="Set date and time"
                      onBlur={() => {
                        setFieldTouched("event_datetime");
                      }}
                    />
                    {touched.event_datetime && errors.event_datetime && (
                      <FormFeedback className="d-block">Required</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup className="mb-5">
                <CustomInput
                  id="event_timezone"
                  name="event_timezone"
                  type="select"
                  value={values.event_timezone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {moment.tz.names().map((tz) => (
                    <option value={tz} key={tz}>
                      {tz}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>

              <FormPrivacySettings
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />

              <Button
                color="primary"
                disabled={isSubmitting || !isValid}
                onClick={submitForm}
              >
                Create Event
              </Button>

              {this.displayError()}

              {/* <Disclaimer className="py-2">
                Don't worry, event will not be published just yet
              </Disclaimer> */}
            </Form>
          )}
        </Formik>
      </Col>
    );
  }
}

export default CreateEvent;
