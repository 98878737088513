import { Auth } from "aws-amplify";
import AppLinks from "components/AppLinks";
import FieldText from "components/FieldText";
import Logo from "components/Logo";
import LogoContainer from "components/Logo/LogoContainer";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import React from "react";
import { Alert, Button, InputGroupAddon, InputGroupText } from "reactstrap";
import styled from "styled-components";
import { usernameAvailabilityValidation } from "utils";
import uuidv4 from "uuid/v4";
import * as Yup from "yup";

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required(
    "Required to provide the best customer support"
  ),
  last_name: Yup.string().required(
    "Required to provide the best customer support"
  ),
  // username: Yup.string()
  //   .min(4, "Too Short!")
  //   .max(50, "Too Long!")
  //   .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6, "Too Short!").required("Required"),
});

const Disclaimer = styled.p`
  font-size: 12px;
`;

const passwordValid = password => password.length >= 6;

class Register extends React.Component {
  constructor () {
    super();

    const utmParamQueryString = new URLSearchParams(window.location.search);
    const clientMetadata = Object.fromEntries(utmParamQueryString);
    const { p1fn, p1ln } = clientMetadata;

    this.state = {
      fields: {
        first_name: p1fn || "",
        last_name: p1ln || "",
        email: "",
        password: "",
        username: "",
      },
      clientMetadata,
      passwordStrengthText: "",
    };
  }


  displayError = (errorMessage) => {
    if (errorMessage.length > 0) {
      return <Alert color="danger">{errorMessage}</Alert>;
    }

    return "";
  };

  onSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    
    const { email, first_name, last_name, password } = values;

    const username = uuidv4();

    try {
      const utmParamQueryString = new URLSearchParams(window.location.search);
      const clientMetadata = Object.fromEntries(utmParamQueryString);

      console.log(clientMetadata);

      let params = {
        username,
        password,
        attributes: {
          email,
          name: `${first_name} ${last_name}`,
          given_name: first_name,
          family_name: last_name,
          // "custom:broadcaster": username,
        },
        validationData: [],
        clientMetadata
      };

      // if (company_name) params.attributes["custom:company_name"] = company_name;

      const data = await Auth.signUp(params);

      const { userConfirmed } = data;

      if (!userConfirmed) {
        throw new Error("Need to confirm user");
      }

      const user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        localStorage.setItem("challenge_temp_user", username);
        localStorage.setItem("challenge_temp_password", password);
        this.props.history.push("/set_password");
        return;
      }

      if (window.rdt) {
        window.rdt("track", "SignUp");
      }

      if (window.tap) {
        window.tap("conversion");
      }

      // const ev = await Auth.verifyCurrentUserAttribute("email");

      window.location.href = "/";
    } catch (err) {
      actions.setSubmitting(false);
      actions.setStatus({ errorMsg: err.message });
    }
  };

  componentWillMount() {
    this.usernameAvailabilityValidation = _.debounce(
      usernameAvailabilityValidation,
      500
    );
  }

  render() {
    const { clientMetadata } = this.state;
    const clientFromJoy = clientMetadata.utm_source === "joy";


    return (
      <Formik
        initialValues={this.state.fields}
        onSubmit={this.onSubmit}
        validationSchema={SignupSchema}
      >
        {({ isSubmitting, isValid, status, values }) => (
          <Form style={{ maxWidth: 500 }}>
            {status && status.errorMsg && this.displayError(status.errorMsg)}
            <div>

              {clientFromJoy && (
                <div className="text-center my-2">
                  "{clientMetadata.eventname}" event will be created automatically.
                </div>
              )}
              {!clientFromJoy && (
                <div className="text-center my-2">
                  <Button
                    color="link"
                    onClick={() => this.props.history.push("/")}
                  >
                    Already have an account? Sign In
                  </Button>
                </div>
              )}
            </div>

            <Field
              type="text"
              name="first_name"
              placeholder="Your First Name"
              component={FieldText}
              autoFocus={!values.first_name}
            />

            <Field
              type="text"
              name="last_name"
              placeholder="Your Last Name"
              component={FieldText}
            />

            <Field
              type="email"
              name="email"
              placeholder="Email"
              component={FieldText}
              autoFocus={!!values.first_name}
            />

            <Field
              type="password"
              name="password"
              // validate={this.passwordStrengthIndicator.bind(this)}
              placeholder="Password"
              component={FieldText}
              addon={() => (
                <InputGroupAddon
                  addonType="append"
                  className={passwordValid(values.password) ? "" : "d-none"}
                >
                  <InputGroupText>
                    <span role="img" aria-label="Good">
                      ✅
                    </span>
                  </InputGroupText>
                </InputGroupAddon>
              )}
            />

            <button
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting && <i className="fa fa-spin fa-spinner"></i>}
              Start and try it Free
            </button>

            <Disclaimer className="text-center mt-2">
              By clicking Sign Up, you are agreeing to the{" "}
              <a
                href="https://eventlive.pro/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </Disclaimer>

            <Disclaimer className="text-center my-3">
              Try streaming in our app, Free
            </Disclaimer>
            <AppLinks />
          </Form>
        )}
      </Formik>
    );
  }
}

export default Register;
