import { API, graphqlOperation } from "aws-amplify";
import FieldText from "components/FieldText";
import FormSubHeader from "components/FormSubHeader";
import FormHeader from "components/FormHeader";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { Field, Formik } from "formik";
import React from "react";
import { Alert, Button, Form } from "reactstrap";
import * as Yup from "yup";

const myQuery = `
  query MyProfile {
    broadcasterProfile {
      username
      email
      company_name
      first_name
      last_name
      credits
      events {
        is_published 
        is_test
      }
    }
  }
`;

const Broadcaster = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  username: Yup.string().required("Required"),
});

const updateMutation = `
  mutation UpdateEmail($email: String!) {
    updateEmail(
      email: $email
    ) {
      email
    }
  }
`;

class Profile extends React.Component {
  state = {};

  componentWillMount = async () => {
    const {
      data: { broadcasterProfile: broadcaster },
    } = await API.graphql(graphqlOperation(myQuery));
    this.setState({
      broadcaster,
    });
  };

  onSubmit = async (values, { setSubmitting }) => {
    const { email } = values;

    const {
      errors,
      data: { updateEmail },
    } = await API.graphql(graphqlOperation(updateMutation, { email }));

    if (!errors) {
      this.setState({ done: true });
    } else {
      this.setState({
        errorMessage:
          "Could not update profile. Please double check and try again.",
      });
    }

    setSubmitting(false);
  };

  render() {
    const { broadcaster, done } = this.state;

    if (!broadcaster) {
      return (
        <div class="d-flex justify-content-center">
          <i className="fa fa-spin fa-spinner"></i>
        </div>
      );
    }

    console.log(broadcaster.events);

    const numEvents = broadcaster.events.filter(e => e.is_published && !e.is_test ).length;

    return (
      <Formik
        initialValues={{ ...broadcaster }}
        validationSchema={Broadcaster}
        onSubmit={this.onSubmit}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form>
            {done && <Alert color="success">Saved</Alert>}

            <FormHeader>Profile</FormHeader>

            <p className="mb-2">
              You have {numEvents} unlocked {numEvents === 1 ? "event" : "events"}.
            </p>
            <p className="mb-5">
              <Icon name="usd" />
              You have {broadcaster.credits || "0"} Stream Credits{" "}
              <Link to="/settings/purchase">Purchase Event Credits</Link>
            </p>

            <FormSubHeader>Update Email Address</FormSubHeader>

            <Field
              label="Email"
              name="email"
              component={FieldText}
              placeholder="Email"
            />

            {/* <Field
              label="Username"
              name="username"
              component={FieldText}
              placeholder="Username"
            /> */}

            <Button
              color="primary"
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default Profile;
