import { API, graphqlOperation } from "aws-amplify";
import BuyOne from "components/BuyOne";
import FormSubHeader from "components/FormSubHeader";
import React from "react";

const myQuery = `
  query MyEvent {
    broadcasterProfile {
      username
      stream_key
    }
  }
`;

class Broadcast extends React.Component {
  state = {};

  componentWillMount = async () => {
    const {
      data: { broadcasterProfile: broadcaster },
    } = await API.graphql(graphqlOperation(myQuery));

    this.setState({
      broadcaster,
    });
  };

  render() {
    const { broadcaster } = this.state;
    return (
      <div>
        <FormSubHeader>Purchase Streaming Credits</FormSubHeader>

        <p>Each event includes:</p>
        <ul>
          <li>Unlimited viewers</li>
          <li>Up to 24 hours HD streaming</li>
          <li>Customise your stream page</li>
          <li>Embed on your website</li>
          <li>Unlimited email and chat support</li>
        </ul>

        <h3>Pack of 10 credits, USD $350 ($35/event) </h3>

        {broadcaster && (
          <div
            dangerouslySetInnerHTML={{
              __html: `

              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_s-xclick">
                <input type="hidden" name="hosted_button_id" value="MDHVYDR4BTHN6">
                <input name="custom" value="${broadcaster.username}" type="hidden">
                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
                <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
              </form>
            `,
            }}
          />
        )}

        <h3 className="mt-5">Single Special Event, USD $50</h3>
        {broadcaster && <BuyOne username={broadcaster.username} />}
      </div>
    );
  }
}

export default Broadcast;
