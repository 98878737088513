import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav as Navigation,
  Navbar as NavigationBar,
  NavbarToggler as NavigationBarToggler,
  NavItem as NavigationItem,
  NavLink as NavigationLink,
  UncontrolledDropdown
} from 'reactstrap'
import styled from 'styled-components'

export default {
  Collapse,
  Container: styled.div`
    width: 100wv;
  `,
  DropdownMenu,
  DropdownItem: styled(DropdownItem)`
    color: #5F5F5F;
  `,
  DropdownToggle,
  HeaderButtons: styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 30px;
  `,
  Logo: styled.img`
    width: 100px;
  `,
  Navigation,
  NavigationBar,
  NavigationBarToggler,
  NavigationItem,
  NavigationLink,
  UncontrolledDropdown,
  AlertPad: styled.div`
    margin-top: 56px;
  `,
  FaIcon: styled.i`
    margin-right: 5px;
    width: 16px;
    text-align: right;
  `,
  FaTopIcon: styled.i`
    margin-right: 5px;
    font-size: 20px;
  `
};