import { API, graphqlOperation } from "aws-amplify";
import BuyOne from "components/BuyOne";
import EventLink from "components/EventLink";
import FormSubHeader from "components/FormSubHeader";
import Icon from "components/Icon";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import styled from "styled-components";
import { downloadGuestbook, formatDate } from "utils";

import eventFragment from "../fragment";

const Shortcuts = styled.ul`
  list-style-type: none;
  padding-left: 0;
  
  li {
    padding: 8px 16px;
  }
`;  


const publishEvent = `
  mutation PublishEvent($slug: String!, $purchaseReceipt: String) {
    publishEvent(
      slug: $slug,
      purchaseReceipt: $purchaseReceipt
    ) {
      ${eventFragment}
    }
  }
`;

class Preview extends React.Component {
  publishEvent = async () => {
    try {
      const { event } = this.props;
      const { errors, data } = await API.graphql(
        graphqlOperation(publishEvent, {
          slug: event.slug,
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { event, username, broadcaster } = this.props;

    const {
      slug,
      event_status,
      is_published,
      is_test,
      stream_key,
      event_window_end_datetime_utc,
    } = event;

    const { credits } = broadcaster;

    const download_url = event.download_url || `https://eventlivehls.sfo2.digitaloceanspaces.com/streams/${stream_key}_final.mp4`;


    // Statuses: 
    // upcoming,  paused,  live,  finished, pending
    // const event_status === "past"
    
    const isExpired =
      new Date().toISOString() > event_window_end_datetime_utc;

    return (
      <>
        <div>
          {is_published && <EventLink {...event} />}

          {!is_published && !credits && (
            <div className="text-left mb-5">
              <FormSubHeader>Event is locked</FormSubHeader>
              <p>Unlock special event live streaming</p>
              <ul>
                <li>Unlimited viewers</li>
                <li>Up to 24 hours HD streaming</li>
                <li>Customise your stream page</li>
                <li>Unlimited email and chat support</li>
                <li>Customise event link</li>
                <li>Get RTMP stream key</li>
              </ul>
              <BuyOne username={username} slug={slug} />
            </div>
          )}
          {!is_published && credits > 0 && (
            <div className="text-center my-3">
              <Button onClick={this.publishEvent}>Unlock With 1 Credit</Button>
            </div>
          )}

          {is_published && event_status === "upcoming" && (
            <>
              <div className="my-3">
                Scheduled for {formatDate(event)}. Streaming is available one
                hour before.
              </div>

              <Shortcuts>
                {is_published && !is_test && (
                  <li>
                    <Icon name="angle-right" />{" "}
                    <Link to={`/event/${slug}/changelink`}>
                      Customise Event Link
                    </Link>
                  </li>
                )}

                <li>
                  <Icon name="angle-right" />{" "}
                  <Link to={`/event/${slug}/guests`}>Invite Guests</Link>
                </li>

                <li>
                  <Icon name="angle-right" />{" "}
                  <Link to={`/event/${slug}/meta`}>
                    Change event name or location
                  </Link>
                </li>
                
                {
                  !is_test && (
                    <li>
                      <Icon name="angle-right" />{" "}
                      <Link to={`/event/${slug}/date`}>Reschedule the event</Link>
                    </li>
                  )
                }
                
                <li>
                  <Icon name="angle-right" />{" "}
                  <Link to={`/event/${slug}/meta`}>
                    Add links to registry or websites
                  </Link>
                </li>
                <li>
                  <Icon name="angle-right" />{" "}
                  <Link to={`/event/${slug}/cover`}>Change cover image</Link>
                </li>

                <li>
                  <Icon name="angle-right" />{" "}
                  <Link to={`/event/${slug}/upload`}>
                    Upload a slideshow or intro video
                  </Link>
                </li>
                <li>
                  <Icon name="angle-right" />{" "}
                  <Link to={`/event/${slug}/guestbook`}>
                    Add a message for your guests
                  </Link>
                </li>
              </Shortcuts>
            </>
          )}

          {is_published && event_status === "past" && (
            <>
              {isExpired && <div className="my-3">Streaming Finished</div>}

              {!isExpired && (
                <div className="my-3">
                  Paused. Streaming available until{" "}
                  {formatDate(event, "event_window_end_datetime_utc")}.
                </div>
              )}

              <Shortcuts>
                <li>
                  <Icon name="angle-right" />{" "}
                  <a href={download_url}>Download Event Video</a>
                </li>
                <li>
                  <Icon name="angle-right" />{" "}
                  <Button
                    style={{ padding: 0 }}
                    color="link"
                    onClick={() => downloadGuestbook(username, slug)}
                  >
                    Download the Guestbook
                  </Button>
                </li>
                <li>
                  <Icon name="angle-right" />{" "}
                  <Link to={`/event/${slug}/upload`}>
                    Re-Upload Event Video
                  </Link>
                </li>
                <li>
                  <Icon name="angle-right" />{" "}
                  <Link to={`/event/${slug}/guests`}>
                    See who joined the stream
                  </Link>
                </li>
                <li>
                  <Icon name="angle-right" />{" "}
                  <Link to={`/event/${slug}/privacy`}>Make video private</Link>
                </li>
              </Shortcuts>
            </>
          )}
        </div>
      </>
    );
  }
}

export default Preview;
