import { API, graphqlOperation } from "aws-amplify";
import FormPrivacySettings from "components/FormPrivacySettings";
import { Field, Formik } from "formik";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import {
  Button,
  Form,
} from "reactstrap";
import * as Yup from "yup";

import eventFragment from "../fragment";

const Event = Yup.object().shape({});

const updateEventMutation = `
  mutation UpdateEventPrivacy($slug: String!, $details: EventPrivacyInput!) {
    updateEventPrivacy(
      slug: $slug,
      details: $details
    ) {
      ${eventFragment}
    }
  }
`;

class Privacy extends React.Component {
  state = {
    done: false,
  };

  onSubmit = async (values, { setSubmitting }) => {
    const {
      event_privacy,
      guestbook_enabled,
      event_password,
      download_enabled,
    } = values;

    const { slug } = this.props.event;
    const details = {
      event_privacy,
      guestbook_enabled,
      event_password,
      download_enabled,
    };

    const {
      errors,
      data: { updateEvent },
    } = await API.graphql(
      graphqlOperation(updateEventMutation, { slug, details })
    );

    if (!errors) {
      this.setState({ done: true });
    } else {
      this.setState({
        errorMessage:
          "Could not update event. Please double check and try again.",
      });
    }

    setSubmitting(false);
  };

  render() {
    const { event } = this.props;
    const { done } = this.state;

    if (done) {
      return <Redirect to={`/event/${event.slug}`} />;
    }

    return (
      <Formik
        initialValues={{ ...event }}
        validationSchema={Event}
        onSubmit={this.onSubmit}
      >
        {({
          values,
          submitForm,
          isSubmitting,
          isValid,
          setFieldValue,
          handleChange,
        }) => (
          <Form>
            <Helmet>
              <title>Privacy</title>
            </Helmet>
            <FormPrivacySettings
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />

            <Button
              color="primary"
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default Privacy;
