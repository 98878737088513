export default `
  id
  broadcaster
  slug
  title
  alias
  event_datetime_utc
  event_window_start_datetime_utc
  event_window_end_datetime_utc
  event_timezone
  event_privacy
  event_location
  event_status
  guestbook_enabled
  download_enabled
  download_pin_enabled
  download_pin
  download_url
  event_password
  event_cover_path
  is_published
  is_test
  stream_key
  vod_filename
  video_uploaded
  vod_converted
  links {
    title
    url
  }
`;
