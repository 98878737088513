import React from 'react';
import CopyrightFooter from 'components/CopyrightFooter';

export default () => (
  <div>
    <h1>eventlive.pro Terms and Conditions</h1>


    <CopyrightFooter />
  </div>
)
