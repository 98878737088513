import startCase from "lodash/startCase";
import moment from "moment-timezone";
import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import styled from "styled-components";
import { formatDate } from "utils";

import VideoStatusBadge from "../VideoStatusBadge";

const VideoCard = styled.div`
  margin-bottom: 30px;
`;

const TitleLink = styled(Link)`
  color: #333;
  :hover {
    text-decoration: none;
    color: #333;
  }
`;

const CardTitle = styled.h6`
  text-decoration: none;
  font-family: "pt sans", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EventCover = styled.div`
  position: relative;
`;

const CardDate = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0 !important;
`;

const CardDetails = styled.div`
  font-size: 12px;
`;

const CardImg = styled.div`
  height: 200px;
  background: url('${(props) => props.src}');
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
  :hover {
    opacity: .8;
  }
`;

const Ribbon = styled.div`
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  right: -3px;

  .ribbon {
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    font-family: "Montserrat Bold", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    letter-spacing: 0.05em;
    line-height: 15px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    right: -11px;
    top: 10px;
    width: 100px;
    height: 28px;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #dedede;
    background-image: -webkit-linear-gradient(top, #ffffff 45%, #dedede 100%);
    background-image: -o-linear-gradient(top, #ffffff 45%, #dedede 100%);
    background-image: linear-gradient(to bottom, #ffffff 45%, #dedede 100%);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffdedede', GradientType=0);
    font-weight: bold;
  }

  .ribbon:before,
  .ribbon:after {
    content: "";
    border-top: 3px solid #9e9e9e;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px;
  }

  .ribbon:before {
    left: 0;
  }

  .ribbon:after {
    right: 0;
  }

  .ribbon.red {
    background-color: #bc1a3a;
    background-image: -webkit-linear-gradient(top, #a61733 45%, #bc1a3a 100%);
    background-image: -o-linear-gradient(top, #a61733 45%, #bc1a3a 100%);
    background-image: linear-gradient(to bottom, #a61733 45%, #bc1a3a 100%);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffa61733', endColorstr='#ffbc1a3a', GradientType=0);
  }

  .ribbon.red:before,
  .ribbon.red:after {
    border-top: 3px solid #8f142c;
  }

  .ribbon.blue {
    background-color: #1a8bbc;
    background-image: -webkit-linear-gradient(top, #177aa6 45%, #1a8bbc 100%);
    background-image: -o-linear-gradient(top, #177aa6 45%, #1a8bbc 100%);
    background-image: linear-gradient(to bottom, #177aa6 45%, #1a8bbc 100%);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#177aa6', endColorstr='#ff1a8bbc', GradientType=0);
  }

  .ribbon.blue:before,
  .ribbon.blue:after {
    border-top: 3px solid #115979;
  }
`;

export default ({
  title,
  slug,
  event_datetime_utc,
  event_timezone,
  event_cover_path,
  event_status,
  event_window_end_datetime_utc,
  event_privacy,
  is_published,
  is_test,
}) => {
  const cover = event_cover_path || "https://via.placeholder.com/300x200";

  const status =
    event_status === "past"
      ? (new Date().toISOString() > event_window_end_datetime_utc
        ? "past"
        : "paused")
      : event_status;

  return (
    <VideoCard className="card">
      <EventCover>
        <Link to={`/event/${slug}`}>
          <CardImg src={cover} title={title} className="card-img-top" />
        </Link>
      </EventCover>
      <div className="card-body">
        {is_test && (
          <Ribbon>
            <div class="ribbon blue">Test</div>
          </Ribbon>
        )}

        {!is_published && (
          <Ribbon>
            <div class="ribbon red">Locked</div>
          </Ribbon>
        )}
        <TitleLink to={`/event/${slug}`}>
          <CardTitle className="card-title">
            <VideoStatusBadge status={status} /> {title}
          </CardTitle>
        </TitleLink>
        <CardDetails>
          <CardDate>
            {formatDate({ event_datetime_utc, event_timezone })}
          </CardDate>
        </CardDetails>
      </div>
    </VideoCard>
  );
};
