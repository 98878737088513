import React, { Fragment } from 'react'

import styled from 'styled-components';

const Icon = styled(({ name, className }) => (
  <i className={`fa fa-${name} ${className}`}></i>
))`
  margin-right: ${props => props.padRight ? '5px' : '0'};
  margin-left: ${props => props.padLeft ? '5px' : '0'};
`;

Icon.defaultProps = {
  padLeft: false,
  padRight: true,
};

export default Icon;
