import AppLinks from "components/AppLinks";
import EventLink from "components/EventLink";
import FormSubHeader from "components/FormSubHeader";
import StreamURLandKey from "components/StreamURLandKey";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Alert,
} from "reactstrap"

const idFromUrl = url => {
  const slug = url.replace(/\/$/, "").split("/").pop();
  console.log(slug);
  const id = slug.split(`-`).pop();
  return id;
}

const Help = ({ event }) => {
  const { is_published, slug } = event;

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    if (typeof $crisp !== "undefined") {
      window.$crisp.push(["on", "helpdesk:queried", ({ results }) => setArticles(results)]);

      window.$crisp.push(["do", "helpdesk:query", ["OBS", "DSLR"]]);
    }
  });

  const openArticle = (url) => (evt) => {
    evt.preventDefault();
    const id = idFromUrl(url);
    window.$crisp.push(["do", "helpdesk:article:open", ["en", id]])
  }

  const now = new Date().toISOString();
  const expired = now > event.event_window_end_datetime_utc;

  return (
    <div>
      <FormSubHeader>Stream with EventLive app</FormSubHeader>
      <Helmet>
        <title>RTMP</title>
      </Helmet>
      <p>
        The easiest way to stream is with our dedicated iOS and Android apps.
        Get EventLive app, sign in with the same email and password and go Live.
      </p>

      <AppLinks />

      <FormSubHeader className="mt-5">Stream via RTMP</FormSubHeader>

      <p>
        Use the RTMP credentials below to stream from your computer,
        professional gear and 3rd party apps.
      </p>

      <ul>
        {articles.map((a) => (
          <li key={a.url}>
            <a href={a.url} onClick={openArticle(a.url)}>
              {a.title}
            </a>
          </li>
        ))}
      </ul>

      {is_published && !expired && (
        <>
          <StreamURLandKey stream_key={event.stream_key} title={event.title} />
          <EventLink
            {...event}
            allowCustomize={false}
            linkTitle="Event Link (for viewing and sharing)"
          />
        </>
      )}

      {expired && (
        <Alert color="warning">
          Streaming Finished. Please create a new test event.
        </Alert>
      )}

      {!is_published && (
        <div>
          <Link to={`/event/${slug}`}>
            <i className="icon fa fa-lock" aria-hidden="true"></i> Unlock event
            to get RTMP Stream Key
          </Link>
        </div>
      )}
    </div>
  );
}

export default Help;
