import styled from 'styled-components'
import DatePicker from 'react-datepicker'

// how many times do i have to place width:!00% till the f* datepicker resizes
export default {
  DatePickerWrapper: styled.div`
    width: 100%;

    & .react-datepicker-wrapper,
    & .react-datepicker__input-container {
      width: 100%;
    }
    
  `,
  DatePicker: styled(DatePicker)`
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: 35px;
    padding: 10px;
    width: 100%;
  `,
}
