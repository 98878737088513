import React, { Fragment } from 'react'
import { Connect } from 'aws-amplify-react'
import { Auth } from 'aws-amplify';
import Logo from 'components/Logo'
import { Link } from 'react-router-dom'
import LogoContainer from 'components/Logo/LogoContainer'
import {
  InputGroup, Input, InputGroupAddon, InputGroupText, Button, Alert, FormFeedback, FormGroup
} from 'reactstrap'
import FieldText from 'components/FieldText';
import CopyrightFooter from 'components/CopyrightFooter';
import styled from 'styled-components'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup';
import { bannedUsernames, usernameAvailabilityValidation } from 'utils'
import moment from 'moment';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  username: Yup.string()
    .min(4, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string().min(6, 'Too Short!').required('Required')
});

const Disclaimer = styled.p`
  font-size: 12px;
`;

class RegisterWedding extends React.Component {

  state = {
    fields: {
      name: '',
      email: '',
      password: '',
      username: ''
    },
    passwordStrengthText: ''
  };

  passwordStrengthIndicator = (value) => {
    let indicatorTexts = ['poor', 'good', 'strong!'];
    if(value.length === 0) {
      return;
    }
    if (!this.zxcvbn) return;
    let res = this.zxcvbn(value);
    res = Math.ceil(res.score/3);
    this.setState({
      passwordStrengthText: indicatorTexts[res],
    });
  };


  displayError = (errorMessage) => {
    if (errorMessage.length > 0) {
      return <Alert color="danger">{ errorMessage }</Alert>
    }

    return ''
  };

  onSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const {
      email,
      name,
      company_name,
      username,
      password,
    } = values;

    try {
      let params = {
        username,
        password,
        attributes: {
          email,
          name,
          'custom:broadcaster': username,
        },
      };

      if(company_name)
        params.attributes['custom:company_name'] = company_name;

      const data = await Auth.signUp(params);

      const { userConfirmed } = data;

      if (!userConfirmed) {
        throw new Error('Need to confirm user');
      }

      const user = await Auth.signIn(username, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        localStorage.setItem('challenge_temp_user', username)
        localStorage.setItem('challenge_temp_password', password)
        this.props.history.push('/set_password')
        return
      }

      const ev = await Auth.verifyCurrentUserAttribute('email')

      window.location.href = '/event/wedding';
    } catch(err) {
      actions.setSubmitting(false);
      actions.setStatus({ errorMsg: err.message });
    }

  };

  componentWillMount() {
    const { search } = this.props.location;
    const query = new URLSearchParams(search)

    const name = query.get('name');
    const partnername = query.get('partnername');
    const email = query.get('email');
    const username = _.snakeCase(`${name} and ${partnername}`);

    this.setState({
      fields: {
        ...this.state.fields,
        name,
        username,
        email,
      }
    })

    this.usernameAvailabilityValidation = _.debounce(usernameAvailabilityValidation, 500);
  }

  async componentDidMount() {
    try {
      this.zxcvbn = await import('zxcvbn');
      this.zxcvbn = this.zxcvbn.default;


    } catch {
      console.error("Error while importing zxcvbn.");
    }
  }

  async validateUsername(value) {
    if (value.length === 0) return;

    const regex = /^[a-z0-9_]{1,50}$/g;
    if(!regex.test(value)) {
      return 'Username should only have lowercase alphanumeric or underscore.';
    } else if(bannedUsernames.includes(value)) {
      return "Username can't be used.";
    } else {
      return await this.usernameAvailabilityValidation(value);
    }
  }

  render() {
    return (
      <Formik
        initialValues={this.state.fields}
        onSubmit={this.onSubmit}
        validationSchema={SignupSchema}
      >
        {({isSubmitting, isValid, status}) => (
          <Form>
            { status && status.errorMsg && this.displayError(status.errorMsg) }
          <LogoContainer>
            <Logo large />
            <p>Sign up for Free. No credit card required</p>
          </LogoContainer>

          <Field
            label="Website Address"
            name="username"
            validate={this.validateUsername.bind(this)}
            component={FieldText}
            placeholder='Username'
            pattern="^[a-z0-9_]{1,50}$"
            minLength="1"
            maxLength="50"
            autoComplete="off"
            addon={() =>
              (<InputGroupAddon
                addonType="append"
              >
                <InputGroupText>.eventlive.pro</InputGroupText>
              </InputGroupAddon>)
            }
          />

          <Field
            label="Email"
            type="email"
            name="email"
            disabled
            placeholder="Email address"
            component={FieldText}
          />

          <Field
            autoFocus
            label="Password"
            type="password"
            name="password"
            validate={this.passwordStrengthIndicator.bind(this)}
            placeholder='*******'
            component={FieldText}
            addon={() =>
              (<InputGroupAddon
                addonType="append"
                className={this.state.passwordStrengthText.length > 0 ? "" : "d-none"}
              >
                <InputGroupText>{this.state.passwordStrengthText}</InputGroupText>
              </InputGroupAddon>)}
          />
          <Disclaimer>
            By clicking below to sign up, you are agreeing to the
            { ' ' }
            <a href="/terms" target="_blank">Terms of Service</a>
          </Disclaimer>

          <button className="btn btn-lg btn-primary btn-block" type="submit"
                  disabled={!isValid || isSubmitting}>
            Sign Up
          </button>

          <div className="text-left" style={{ marginTop: 40 }}>
            <Button color="link" onClick={() => this.props.history.push('/')}>
              &larr; Already have an account?
            </Button>
          </div>

          <CopyrightFooter />
        </Form>
        )}
      </Formik>
    );
  }
}

export default RegisterWedding;
