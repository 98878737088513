import { API, graphqlOperation } from "aws-amplify";
import DatePicker from "components/DatePicker";
import FormSubHeader from "components/FormSubHeader";
import { Formik } from "formik";
import moment from "moment-timezone";
import React from "react";
import {Helmet} from "react-helmet"
import { Redirect } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row
} from "reactstrap";
import * as Yup from "yup";

import eventFragment from "../fragment";

const convertDate = (date, tz) =>
  moment(date)
    .tz(tz)
    .format("YYYY-MM-DD");
const convertTime = (date, tz) =>
  moment(date)
    .tz(tz)
    .format("HH:mm");

const Event = Yup.object().shape({
  event_datetime: Yup.date().required("Required"),
});

const updateEventMutation = `

  mutation UpdateEventDateTime($slug: String!, $details: EventDateTimeInput!) {
    updateEventDateTime(
      slug: $slug,
      details: $details
    ) {
      ${eventFragment}
    }
  }
`;

class DateTime extends React.Component {
  state = {
    done: false
  };

  onSubmit = async (values, { setSubmitting }) => {
    const { event_datetime, event_timezone } = values;

    const event_date = moment(event_datetime).format('YYYY-MM-DD');
    const event_time = moment(event_datetime).format('HH:mm');

    const details = {
      event_date,
      event_time,
      event_timezone
    };

    const { slug } = this.props.event;

    const {
      errors,
      data: { updateEvent }
    } = await API.graphql(
      graphqlOperation(updateEventMutation, { slug, details })
    );

    if (!errors) {
      this.setState({ done: true });
    } else {
      this.setState({
        errorMessage:
          "Could not update event. Please double check and try again."
      });
    }

    setSubmitting(false);
  };

  render() {
    const { event } = this.props;
    const { done } = this.state;

    if (done) {
      return <Redirect to={`/event/${event.slug}`} />;
    }

    const {
      event_datetime_utc,
      event_timezone,
      event_window_start_datetime_utc,
      event_window_end_datetime_utc,
    } = event;

    
    const event_datetime = new Date(moment.tz(new Date(event_datetime_utc), event_timezone).format('YYYY-MM-DDTHH:mm:00'));


    return (
      <Formik
        initialValues={{
          event_datetime,
          event_timezone,
          event_start_date: convertDate(
            event_window_start_datetime_utc,
            event_timezone
          ),
          event_start_time: convertTime(
            event_window_start_datetime_utc,
            event_timezone
          ),
          event_end_date: convertDate(
            event_window_end_datetime_utc,
            event_timezone
          ),
          event_end_time: convertTime(
            event_window_end_datetime_utc,
            event_timezone
          ),
        }}
        validationSchema={Event}
        onSubmit={this.onSubmit}
      >
        {({
          values,
          submitForm,
          isSubmitting,
          errors,
          touched,
          isValid,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <FormSubHeader>Date and Time</FormSubHeader>
            <Helmet>
              <title>Change Date or Time</title>
            </Helmet>

            <Row form>
              <Col md={12}>
                <FormGroup>
                  <DatePicker
                    selected={values.event_datetime}
                    onChange={(value) => {
                      setFieldValue("event_datetime", value);
                    }}
                    showTimeSelect
                    timeFormat="h:mm aa"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText="Set date and time"
                    onBlur={() => {
                      setFieldTouched("event_datetime");
                    }}
                  />
                  {touched.event_datetime && errors.event_datetime && (
                    <FormFeedback className="d-block">Required</FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="field_event_timezone">Event Time Zone</Label>
              <CustomInput
                id="field_event_timezone"
                name="event_timezone"
                type="select"
                value={values.event_timezone}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {moment.tz.names().map((tz) => (
                  <option value={tz} key={tz}>
                    {tz}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>

            {/* <FormSubHeader>Streaming Window</FormSubHeader>
            <p>When you start streaming using your stream key during this window the stream is mapped to this event.</p>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="field_event_date">Start Date</Label>
                  <Input
                    id="field_event_date"
                    name="event_start_date"
                    type="date"
                    min={moment().format('YYYY-MM-DD')}
                    value={values.event_start_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={!!(touched.event_start_date && errors.event_start_date)}
                  />
                  {errors.event_start_date && touched.event_start_date && <FormFeedback>{errors.event_start_date}</FormFeedback>}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="field_event_time">Start Time</Label>
                  <Input
                    id="field_event_time"
                    name="event_start_time"
                    type="time"
                    value={values.event_start_time}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={!!(touched.event_start_time && errors.event_start_time)}
                  />
                  {errors.event_start_time && touched.event_start_time && <FormFeedback>{errors.event_start_time}</FormFeedback>}
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="field_event_date">End Date</Label>
                  <Input
                    id="field_event_date"
                    name="event_end_date"
                    type="date"
                    min={moment().format('YYYY-MM-DD')}
                    value={values.event_end_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={!!(touched.event_end_date && errors.event_end_date)}
                  />
                  {errors.event_end_date && touched.event_end_date && <FormFeedback>{errors.event_end_date}</FormFeedback>}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="field_event_time">End Time</Label>
                  <Input
                    id="field_event_time"
                    name="event_end_time"
                    type="time"
                    value={values.event_end_time}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={!!(touched.event_end_time && errors.event_end_time)}
                  />
                  {errors.event_end_time && touched.event_end_time && <FormFeedback>{errors.event_end_time}</FormFeedback>}
                </FormGroup>
              </Col>
            </Row> */}

            {touched.event_datetime && event.is_published && (
              <Alert color="warning">
                Please note. Event is already published, an email notification
                about date or time change will be sent to all guests that have
                registered so far.
              </Alert>
            )}

            <Button
              color="primary"
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default DateTime;
