import { API, graphqlOperation } from "aws-amplify";
import Icon from "components/Icon";
import VideoThumbnail from "components/VideoThumbnail";
import sort from "lodash/orderBy";
import moment from "moment-timezone";
import React from "react";
import {Helmet} from "react-helmet"
import { Button, Col, Container, Row } from "reactstrap";
import styled from "styled-components";

import fields from "../EditEvent/fragment";

const eventsQuery = `
  query myEvents {
    myEvents {
      id
      title
      event_datetime_utc
      event_timezone
      slug
      event_cover_path
      event_status
      event_privacy
      is_test
      is_published
      event_window_end_datetime_utc
    }
  }
`;

const EventsList = ({ events }) => (
  <Container>
    <Row>
      {events.map((event) => (
        <Col sm={4} key={event.slug}>
          <VideoThumbnail {...event} />
        </Col>
      ))}
    </Row>
  </Container>
);

const FirstTime = styled.div`
  margin-top: 20px;
`;

const IntroHeader = styled.h1`
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 0;
`;

const Intro = ({ createEvent, createTest }) => (
  <FirstTime>
    <div className="row title">
      <div className="col-sm-12 text-center">
        <IntroHeader>Welcome to EventLive</IntroHeader>
        <p>Create your first livestreaming event</p>
      </div>
    </div>
    <div className="row body"></div>
    <div className="row action">
      <div className="col-sm-12 text-center">
        <Button color="primary" onClick={createEvent} className="mr-3">
          Create an Event
        </Button>

        <Button color="primary" outline onClick={createTest}>
          Test, Free
        </Button>
      </div>
    </div>
  </FirstTime>
);

const createTestEventMutation = `
  mutation CreateTestEvent($broadcaster: String!, $details: TestEventInput!) {
    createTestEvent(
      broadcaster: $broadcaster,
      details: $details
    ) {
       ${fields}
    }
  }
`;

export default class Dashboard extends React.Component {
  state = {};

  componentDidMount = async () => {
    const {
      data: { broadcaster },
    } = await API.graphql(
      graphqlOperation(`query { broadcaster: broadcasterProfile { username } }`)
    );

    this.setState({
      username: broadcaster.username
    });

    this.fetchEvents();

    this.subscription = API.graphql(
      graphqlOperation(
        `
      subscription eventUpdated($broadcaster: String!) {
        eventUpdated(broadcaster: $broadcaster) {
          title
          slug
          broadcaster
          event_datetime_utc
          event_privacy
          event_status
          event_location
        }
      }
      `,
        { broadcaster: broadcaster.username }
      )
    ).subscribe({
      next: () => {
        this.fetchEvents();
      },
    });
  };

  fetchEvents = async () => {
    const {
      data: { myEvents },
      loading,
      error,
    } = await API.graphql(graphqlOperation(eventsQuery));

    this.setState({
      myEvents,
      error,
      loading,
    });
  };

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  newTest = async () => {
    // create Event
    const {
      data: { createTestEvent },
    } = await API.graphql(
      graphqlOperation(createTestEventMutation, {
        broadcaster: this.state.username,
        details: {
          title: "New Test Event",
          event_timezone: moment.tz.guess(),
        },
      })
    );
    console.log(createTestEvent);
    this.props.history.push(`/event/${createTestEvent.slug}`);
  };

  render() {
    const { history } = this.props;
    const { myEvents, error, loading } = this.state;

    if (error) return <h3>Error</h3>;
    if (loading || !myEvents) return (
      <div class="d-flex justify-content-center">
        <i className="fa fa-spin fa-spinner"></i>
      </div>
    );

    const createEvent = () => history.push("/create_event");

    if (!myEvents.length) {
      return <Intro createEvent={createEvent} createTest={this.newTest} />;
    }

    return (
      <>
        <Helmet><title>Dashboard</title></Helmet>
        <div className="text-center mb-3">
          <Button color="primary" className="mr-3" onClick={createEvent}>
            <Icon name="calendar" /> Create an Event
          </Button>
          <Button color="primary" outline onClick={this.newTest}>
            <Icon name="plus" /> New Test
          </Button>
        </div>
        <EventsList events={sort(myEvents, "event_datetime_utc", ["desc"])} />
      </>
    );
  }
}
