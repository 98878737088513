import FieldText from "components/FieldText";
import FormSubHeader from "components/FormSubHeader";
import { Field } from "formik";
import React, { Fragment } from "react";
import { CustomInput, FormGroup } from "reactstrap";
import { privacySettings } from "utils";

export default ({ values, handleChange, setFieldValue }) => (
  <Fragment>
    <FormSubHeader>Privacy</FormSubHeader>
    <FormGroup>
      {privacySettings.map(({ value, label }, i) => (
        <CustomInput
          key={value}
          value={value}
          type="radio"
          id={`field_event_privacy${i}`}
          name="event_privacy"
          checked={values.event_privacy === value}
          label={label}
          onChange={handleChange}
        />
      ))}
    </FormGroup>

    {values.event_privacy === "password_protected" && (
      <Field
        label="Event Password"
        name="event_password"
        component={FieldText}
        placeholder="***"
        className="col-4"
      />
    )}

    <FormSubHeader className="mt-5">Guest Book</FormSubHeader>

    <FormGroup>
      {[
        { value: true, label: "Enabled" },
        { value: false, label: "Disabled" },
      ].map(({ value, label }, i) => (
        <CustomInput
          key={value}
          value={value}
          type="radio"
          id={`field_guestbook_enabled${i}`}
          name="guestbook_enabled"
          checked={values.guestbook_enabled === value}
          onChange={(e) => {
            setFieldValue("guestbook_enabled", e.target.value === "true");
          }}
          label={label}
        />
      ))}
    </FormGroup>

    <FormSubHeader className="mt-5">Downloads for Guests</FormSubHeader>

    <FormGroup>
      {[
        { value: true, label: "Enabled" },
        { value: false, label: "Disabled" },
      ].map(({ value, label }, i) => (
        <CustomInput
          key={value}
          value={value}
          type="radio"
          id={`field_download_enabled${i}`}
          name="download_enabled"
          checked={values.download_enabled === value}
          onChange={(e) => {
            setFieldValue("download_enabled", e.target.value === "true");
          }}
          label={label}
        />
      ))}
    </FormGroup>
  </Fragment>
);
