import FormSubHeader from "components/FormSubHeader";
import React, { useEffect, useState } from "react";
import CSVReader from 'react-csv-reader'
import { Helmet } from "react-helmet";
import { Badge, Button, Table } from "reactstrap";

import API, { graphqlOperation } from "@aws-amplify/api";

const inviteGuestsMutation = `
  mutation registerGuests($event_id: String!, $guests: [GuestInput], $sendInvite: Boolean) {
    registerGuests(
      event_id: $event_id,
      guests: $guests,
      sendInvite: $sendInvite
    ) {
      id
    }
  }
`;


const commentsQuery = (broadcaster, slug) =>
  graphqlOperation(
    `
    query event($broadcaster: String!, $slug: String!) {
        event(broadcaster: $broadcaster, slug: $slug) {        
            guests {
                id
                name 
                email
            }
            guestsCount
        }
    }
    `,
    { broadcaster, slug }
  );


const Guests = ({ event }) => {
  const { slug, broadcaster } = event;

  const [eventWithGuests, setEventWithGuests] = useState(event);

  const [guestsToInvite, setGuestsToInvite] = useState([]);

  const [addGuestEmail, setAddGuestEmail] = useState("");
  const [addGuestName, setAddGuestName] = useState("");

  const [submitting, setSubmitting] = useState(false);

  const fetchGuests = async () => {
    try {
      const { data } = await API.graphql(commentsQuery(broadcaster, slug));

      setEventWithGuests({ ...event, ...data.event });
    } catch (err) {
        console.error(err);
    }
  };

  useEffect(() => {
    fetchGuests();
  }, []);

  const importFile = (data, fileInfo) => {

    setGuestsToInvite([
      ...guestsToInvite,
      ...data.map(guest => {
        return {
          name: (guest.first_name || guest.last_name) ? `${guest.first_name} ${guest.last_name || ''}` : guest.name,
          email: guest.email
        };
      }).filter(g => !!g.email)
    ])
  }

  const deleteGuest = (i) => {
    setGuestsToInvite(guestsToInvite.filter((e, index) => (index !== i)));
  }

  const clearGuest = () => {
    setGuestsToInvite([]);
  }

  const addSingleGuest = () => {
    setGuestsToInvite([
      ...guestsToInvite,
      {
        name: addGuestName,
        email: addGuestEmail
      }
    ]);

    setAddGuestEmail("");
    setAddGuestName("");
  }

  const inviteGuests = async (sendInvites = true) => {
    try {
      setSubmitting(true);

      // console.log(
      //   JSON.stringify({
      //     event_id: event.id,
      //     guests: guestsToInvite,
      //     sendInvite: true,
      //   })
      // );
      const {
        data: { registerGuests },
        errors,
      } = await API.graphql(
        graphqlOperation(inviteGuestsMutation, {
          event_id: event.id,
          guests: guestsToInvite,
          sendInvite: true
        })
      );

      fetchGuests();

      setGuestsToInvite([]);
      setAddGuestEmail([]);
      setAddGuestName([]);
      setSubmitting(false);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  }

  const {
    guestsCount,
    guests = [],
  } = eventWithGuests;

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  return (
    <div>
      <FormSubHeader>Invite Guests</FormSubHeader>

      <div>
        <p>
          Upload your guest list in csv format to invite multiple guests at once
          or enter manually below.
        </p>
        <p>
          Required column: <b>Email</b>. Optional columns:{" "}
          <b>Name, First Name, Last Name</b>
        </p>
        <CSVReader parserOptions={papaparseOptions} onFileLoaded={importFile} />

        <Table className="mt-4">
          <thead>
            <tr>
              <th>Email</th>
              <th>Name</th>
              <th>
                {guestsToInvite.length > 1 && (
                  <Button color="link" onClick={clearGuest}>
                    Clear List
                  </Button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {guestsToInvite.map((guest, i) => (
              <tr>
                <td>{guest.email}</td>
                <td>{guest.name}</td>
                <td>
                  <Button color="link" onClick={() => deleteGuest(i)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}

            <tr>
              <td>
                <input
                  placeholder="Email (required)"
                  type="email"
                  value={addGuestEmail}
                  onChange={(evt) => setAddGuestEmail(evt.target.value)}
                />
              </td>
              <td>
                <input
                  placeholder="Name (optional)"
                  value={addGuestName}
                  onChange={(evt) => setAddGuestName(evt.target.value)}
                />
              </td>
              <td>
                <Button
                  color="link"
                  disabled={!addGuestEmail}
                  onClick={addSingleGuest}
                >
                  Add
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>

        {guestsToInvite.length > 0 && (
          <div>
            <p>
              <em>
                Send the guests a "You've been invited" email with joining
                instructions, and subscribe them to reminders.
              </em>
            </p>
            <Button
              color="primary"
              className="mr-3"
              disabled={submitting}
              onClick={inviteGuests}
            >
              Invite {guestsToInvite.length}{" "}
              {guestsToInvite.length === 1 ? "Guest" : "Guests"}
            </Button>
          </div>
        )}
      </div>

      <FormSubHeader className="mt-5">
        Guest List <Badge color="secondary">{guestsCount}</Badge>
      </FormSubHeader>
      <Helmet>
        <title>RSVP List</title>
      </Helmet>

      <ul>
        {guests.map((guest) => (
          <li key={guest.id}>
            <a href={`mailto:${guest.email}`}>{guest.email}</a>
            {guest.name && ` (${guest.name})`}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Guests;
