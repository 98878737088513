import React, { Fragment } from 'react'
import Icon from 'components/Icon';
import FormHeader from 'components/FormHeader'
import FormSubHeader from 'components/FormSubHeader'
import EventLink from 'components/EventLink'

class Help extends React.Component {
  render() {
    const { event, username, broadcaster } = this.props;
    const { is_published, slug } = event;

    return (
      <div>
        <FormHeader>{event.title}</FormHeader>
        <FormSubHeader>Share event private link by email</FormSubHeader>

        {
          is_published &&
            <EventLink broadcaster={username} slug={slug} />
        }
      </div>
    );
  }
};

export default Help;
