import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { Auth } from 'aws-amplify'
import React from 'react'
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";

import Authenticated from './Authenticated'
import Unauthenticated from './Unauthenticated'
import CheckYourEmail from "./Unauthenticated/CheckYourEmail";
import ForgotPassword from "./Unauthenticated/ForgotPassword";
import SetPassword from "./Unauthenticated/SetPassword";
import Terms from "./Unauthenticated/Terms";

class CheckAuth extends React.Component {
  state = { loading: true, mobileLogin: false, hideNav: false };

  componentWillMount = async () => {
    console.log(this.props.match);

    const signIn = async ({ idToken, refreshToken, accessToken, username }) => {
      const localSession = new CognitoUserSession({
        IdToken: new CognitoIdToken({
          IdToken: idToken,
        }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: refreshToken,
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: accessToken,
        }),
      });

      const localUser = new CognitoUser({
        Username: username,
        Pool: Auth.userPool,
        Storage: Auth.userPool.storage,
      });
      localUser.setSignInUserSession(localSession);
      Auth.currentCredentials = async () => localSession;

      try {
        await Auth.currentSession();
        console.warn(`mobile login current session!!`);
        this.setState({
          loading: false,
          signedIn: true,
          username,
          mobileLogin: true,
        });
      } catch (ex) {
        console.warn(`mobile login ${ex}`);
      }
    }

    let queryString = new URLSearchParams(window.location.search);
    let hideNavq = queryString.get("hideNav");
    const idToken = queryString.get("idToken");
    const refreshToken = queryString.get("refreshToken");
    const accessToken = queryString.get("accessToken");
    const username = queryString.get("username");

    if (idToken) {
      signIn({ idToken, refreshToken, accessToken, username })
    }

    const hideNav = !!window.localStorage.getItem("hideNav") || hideNavq;

    this.setState({
      hideNav,
    });

    if (hideNav) {
      window.localStorage.setItem("hideNav", hideNav);
    }

    window.mobileLogin = async (mobileSession) => {
      signIn({ idToken: mobileSession.idToken.jwtToken, 
        refreshToken: mobileSession.refreshToken, 
        accessToken: mobileSession.accessToken.jwtToken,
        username: mobileSession.accessToken.payload.username 
      });
    };

    try {
      const user = await Auth.currentAuthenticatedUser();
      const { username } = user;
      this.setState({ loading: false, signedIn: true, username });
    } catch (error) {
      this.setState({ loading: false, signedIn: false });
      console.log(error);
    }
  };
  render() {
    const { loading, hideNav, signedIn } = this.state;
    const showLoading = loading || (hideNav && !signedIn);
    return showLoading ? (
      <div class="d-flex justify-content-center">
        <i className="fa fa-spin fa-spinner"></i>
      </div>
    ) : signedIn ? (
      <Authenticated />
    ) : (
      <Unauthenticated />
    );
  }
}


const Application = () => (
  <>
    <Helmet titleTemplate="%s - EventLive Broadcaster"></Helmet>
    <Switch>
      <Route exact path="/set_password" component={SetPassword} />
      <Route exact path="/forgot_password" component={ForgotPassword} />
      <Route exact path="/check_your_email" component={CheckYourEmail} />
      <Route exact path="/terms" component={Terms} />
      <Route path="*" component={CheckAuth} />
    </Switch>
  </>
);

export default Application;





