import { Auth } from "aws-amplify";
import Logo from "components/Logo";
import LogoContainer from "components/Logo/LogoContainer";
import React from "react";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";

export default class Signin extends React.Component {
  state = {
    fields: {
      email: "",
      password: "",
    },
    canSubmit: true,
    errorMessage: "",
  };

  changeField = (field, value) =>
    this.setState({
      fields: { ...this.state.fields, [field]: value },
    });

  updateField = (name) => (e) => {
    this.changeField(name, e.target.value);
  };

  displayError = () => {
    if (this.state.errorMessage.length > 0) {
      return <Alert color="danger">{this.state.errorMessage}</Alert>;
    }

    return "";
  };

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ canSubmit: false, loading: true });
      const user = await Auth.signIn(
        this.state.fields.email,
        this.state.fields.password
      );
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        localStorage.setItem("challenge_temp_user", this.state.fields.email);
        localStorage.setItem(
          "challenge_temp_password",
          this.state.fields.password
        );
        this.props.history.push("/set_password");
        return;
      }
      let { from } = this.props.location.state || {
        from: { pathname: "/" },
      };
      this.props.history.push(from.pathname);
      window.location = from.pathname;
    } catch (e) {
      console.log(e);
      if (e.name === "UserNotFoundException") {
        this.setState({
          errorMessage:
            "Could not find an account with this email. Please note: E-mail is case-sensitive.",
        });
      } else if (e.name === "NotAuthorizedException") {
        this.setState({
          errorMessage: "Your email or password is incorrect",
        });
      } else {
        this.setState({
          errorMessage: "There was a problem authenticating you.",
        });
      }
      this.setState({ canSubmit: true, loading: false });
    } finally {
      
    }
  };

  render = () => (
    <Form onSubmit={this.onSubmit} className="form-signin">
      {this.displayError()}

      <FormGroup>
        <Input
          autoFocus
          id="field_email"
          type="email"
          placeholder="E-mail"
          value={this.state.fields.email}
          onChange={this.updateField("email")}
          autoCorrect="off"
          autoCapitalize="none"
        />
      </FormGroup>

      <FormGroup>
        <Input
          id="field_password"
          placeholder="Password"
          type="password"
          value={this.state.fields.password}
          onChange={this.updateField("password")}
        />
      </FormGroup>

      <button
        className="btn btn-lg btn-primary btn-block"
        type="submit"
        disabled={!this.state.canSubmit}
      >
        {this.state.loading && <i className="fa fa-spin fa-spinner"></i>}
        Sign in
      </button>

      <div className="text-center mt-3">
        <Button
          color="link"
          onClick={() => this.props.history.push("/forgot_password")}
        >
          Forgot your password?
        </Button>

        <Button color="link" onClick={() => this.props.history.push("/signup")}>
          Create an Account
        </Button>
      </div>
    </Form>
  );
}
