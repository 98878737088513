import {Redirect, Route} from 'react-router-dom';
import React from 'react';

function RedirectWithState({ component: Component, ...rest }) {
  return (
    <Route
      path="*"
      {...rest}
      render={props =>
        (<Redirect
            to={{
              pathname: rest.to,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default RedirectWithState;
