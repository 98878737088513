import React from 'react';
import { Alert } from 'reactstrap';
import { Auth } from 'aws-amplify'
import { Redirect } from 'react-router-dom';
import CopyrightFooter from 'components/CopyrightFooter';


export default class VerifyEmail extends React.Component {
  state = {}


  // TODO verify
  async componentDidMount() {
    const { code } = this.props.match.params;
    try {
      const res = await Auth.verifyCurrentUserAttributeSubmit('email', code);
      this.setState({
        success: true
      })
    } catch(err) {
      this.setState({
        error: err.message
      })
    }
  }

  render() {
    return (
      <form className="form-signin text-center">

        eventlive.pro

        {
          this.state.success &&
            <Redirect to="/?email_verified" />
        }

        {
          this.state.error &&
            <Alert color="danger">{this.state.error}</Alert>
        }

        <CopyrightFooter />
      </form>
    );
  }
}
