import React, { Fragment } from 'react'
import { Auth, API, graphqlOperation } from 'aws-amplify';
import Icon from 'components/Icon';
import FormHeader from 'components/FormHeader'
import FormSubHeader from 'components/FormSubHeader'
import StreamURLandKey from 'components/StreamURLandKey'

const myQuery = `
  query MyEvent {
    broadcasterProfile {
      stream_key
    }
  }
`;

class Broadcast extends React.Component {
  state = {}

  componentWillMount = async () => {
    const {
      data: { broadcasterProfile: broadcaster }
    } = await API.graphql(graphqlOperation(myQuery));

    this.setState({
      broadcaster
    })
  }

  render() {
    const { username, broadcaster } = this.state;

    return (
      <div>
        <FormSubHeader>Streaming Settings</FormSubHeader>
        {
          broadcaster &&
            <StreamURLandKey broadcaster={broadcaster} />
        }

        <p>
          <Icon name="question-circle" />Need help? Check out our {' '}
          <a href="https://help.eventlive.pro/help/simplest-way-to-start-live-streaming" target="_blank">Knowledge Base</a>
        </p>
      </div>
    );
  }
};

export default Broadcast;
