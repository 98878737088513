import FormSubHeader from "components/FormSubHeader";
import Uploader from "components/Uploader";
import React from "react";
import { Helmet } from "react-helmet"
import { Redirect } from "react-router-dom";

class Cover extends React.Component {
  state = {};


  render() {
    const { event } = this.props;
    const { done } = this.state;

    if (done) {
      return (
        <Redirect
          to={{
            pathname: `/event/${event.slug}`,
            state: {
              successNote:
                "Your new cover is uploaded and will be updated in a moment!",
            },
          }}
        />
      );
    }

    return (
      <div>
        <FormSubHeader>Upload Cover Photo</FormSubHeader>
        <Helmet>
          <title>Upload Cover</title>
        </Helmet>
        <p>
          Image will be resized and cropped to 1280x720 automatically.
          Horizontal images work better.
        </p>

        <Uploader
          bucket={process.env.REACT_APP_COVER_BUCKET}
          makeKey={(first) => {
            const [extension, ...parts] = first.name.split(".").reverse();
            // Add random number to support replacing the file with same filename, get rid of bad chars:
            const filename = (
              parts.reverse().join("-") +
              "_" +
              Math.round(Math.random(0, 1) * 10000) +
              "." +
              extension
            ).replace(/[^\w\d-]/g, "-");
            return `${this.props.username}/${this.props.event.slug}/${filename}`;
          }}
          onDone={() => this.setState({ done: true })}
        />
      </div>
    );
  }
}

export default Cover;
