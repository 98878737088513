import { API, graphqlOperation } from "aws-amplify";
import FormHeader from "components/FormHeader";
import Icon from "components/Icon";
import React from "react";
import {Helmet} from "react-helmet"
import { NavLink as RRNavLink, Route, Switch } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Collapse,
  Nav,
  NavLink,
  Row,
} from "reactstrap";
import styled from "styled-components";

import eventFragment from "./fragment";
import Cover from "./tabs/Cover";
import DateTime from "./tabs/DateTime";
import EditLink from "./tabs/EditLink";
import Embed from "./tabs/Embed";
import Guestbook from "./tabs/Guestbook";
import Guests from "./tabs/Guests";
import Help from "./tabs/Help";
import Meta from "./tabs/Meta";
import Preview from "./tabs/Preview";
import Privacy from "./tabs/Privacy";
import Share from "./tabs/Share";
import Upload from "./tabs/UploadVOD";

const myEventQuery = `
  query MyEvent($slug: String!) {
    broadcasterProfile {
      stream_key
      username
      credits
    }
    myEvent(slug: $slug) {
      ${eventFragment}
    }
  }
`;

const publishEventMutation = `
  mutation PublishEvent($slug: String!, $purchaseReceipt: String!) {
    publishEvent(
      slug: $slug,
      purchaseReceipt: $purchaseReceipt
    ) {
      ${eventFragment}
    }
  }
`;

const deleteEventMutation = `
  mutation DeleteEvent($slug: String!) {
    deleteEvent(slug: $slug) {
      slug
    }
  }
`;

const MyCollapse = styled(Collapse)`
  @media (min-width: 767px) {
    &:not(.show) {
      display: block !important;
    }
  }
`;

const MyToggle = styled(Button)`
  @media (min-width: 767px) {
    display: none;
  }
`;

class EditEvent extends React.Component {
  state = {
    loading: true,
    fields: {},
  };

  componentWillMount = async () => {
    const { slug } = this.props.match.params;
    const {
      data: { myEvent, broadcasterProfile: broadcaster },
    } = await API.graphql(graphqlOperation(myEventQuery, { slug }));

    const { username, credits } = broadcaster;

    this.subscription = API.graphql(
      graphqlOperation(
        `
      subscription eventUpdated($broadcaster: String! $slug: String!) {
        eventUpdated(broadcaster: $broadcaster, slug: $slug) {
          ${eventFragment}
        }
      }
      `,
        { broadcaster: username, slug }
      )
    ).subscribe({
      next: ({ value }) => {
        const fields = { ...this.state.fields, ...value.data.eventUpdated };
        this.setState({ fields });
      },
    });

    this.setState({
      username,
      fields: myEvent,
      broadcaster,
      loading: false,
      credits,
    });
  };

  publishEvent = async () => {
    this.setState({ loading: true });

    const { slug } = this.props.match.params;
    const {
      data: { publishEvent },
    } = await API.graphql(
      graphqlOperation(publishEventMutation, {
        slug,
        purchaseReceipt: "CREDIT",
      })
    );

    this.setState({
      fields: publishEvent,
      loading: false,
      successNote:
        'The event is now published! Check out "How to stream" section to learn more!',
    });
  };

  deleteEvent = async () => {
    if (!window.confirm("Are you sure?")) return;

    try {
      const { slug } = this.props.match.params;

      await API.graphql(graphqlOperation(deleteEventMutation, { slug }));

      this.props.history.push("/");
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { match, location } = this.props;
    const { state } = location;
    const successNote = this.state.successNote || (state && state.successNote);
    const event = this.state.fields;
    const { username, broadcaster } = this.state;
    const { slug, is_published, is_test } = event;

    // const isPreviewPage = !match.params.tab;

    if (this.state.loading) {
      return (
        <div class="d-flex justify-content-center">
          <i className="fa fa-spin fa-spinner"></i>
        </div>
      );
    }

    return (
      <>
        <Helmet titleTemplate={`%s - ${event.title} - EventLive Broadcaster`}><title>Summary</title></Helmet>
        <FormHeader>
          <MyToggle
            color="link"
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          >
            <i className="fa fa-bars"></i>
          </MyToggle>

          <span>{event.title}</span>
        </FormHeader>
        <Row>
          <Col sm={3} xs={12}>
            <MyCollapse isOpen={this.state.isOpen} navbar>
              <Nav vertical className="mb-3">
                <NavLink
                  to={`/event/${slug}`}
                  onClick={() => this.setState({ isOpen: false })}
                  tag={RRNavLink}
                >
                  <Icon name="link" />
                  Link and Summary
                </NavLink>
                <NavLink
                  to={`/event/${slug}/how_to`}
                  onClick={() => this.setState({ isOpen: false })}
                  tag={RRNavLink}
                >
                  <Icon name="video-camera" />
                  How To / RTMP
                </NavLink>
                <NavLink
                  to={`/event/${slug}/meta`}
                  onClick={() => this.setState({ isOpen: false })}
                  tag={RRNavLink}
                >
                  <Icon name="cog" />
                  Event Details
                </NavLink>
                <NavLink
                  to={`/event/${slug}/privacy`}
                  onClick={() => this.setState({ isOpen: false })}
                  tag={RRNavLink}
                >
                  <Icon name="lock" />
                  Privacy Settings
                </NavLink>
                {
                  !is_test && (
                    <NavLink
                      to={`/event/${slug}/date`}
                      onClick={() => this.setState({ isOpen: false })}
                      tag={RRNavLink}
                    >
                      <Icon name="calendar" />
                      Change Date or Time
                    </NavLink>
                  )
                }
                
                <NavLink
                  to={`/event/${slug}/cover`}
                  onClick={() => this.setState({ isOpen: false })}
                  tag={RRNavLink}
                >
                  <Icon name="image" />
                  Change Cover
                </NavLink>
                <NavLink
                  to={`/event/${slug}/upload`}
                  onClick={() => this.setState({ isOpen: false })}
                  tag={RRNavLink}
                >
                  <Icon name="file-video-o" />
                  Upload Video
                </NavLink>
                <NavLink
                  to={`/event/${slug}/embed`}
                  onClick={() => this.setState({ isOpen: false })}
                  tag={RRNavLink}
                >
                  <Icon name="code" />
                  Embed
                </NavLink>

                <NavLink
                  to={`/event/${slug}/guests`}
                  onClick={() => this.setState({ isOpen: false })}
                  tag={RRNavLink}
                >
                  <Icon name="user" />
                  RSVP &amp; Invite
                </NavLink>

                <NavLink
                  to={`/event/${slug}/guestbook`}
                  onClick={() => this.setState({ isOpen: false })}
                  tag={RRNavLink}
                >
                  <Icon name="book" />
                  Guestbook
                </NavLink>
                {(!is_published || is_test) && (
                  <NavLink
                    to={`#`}
                    onClick={this.deleteEvent}
                    tag={RRNavLink}
                    style={{ color: "red" }}
                  >
                    <Icon name="trash" />
                    Delete
                  </NavLink>
                )}
              </Nav>
            </MyCollapse>
          </Col>
          <Col sm={9} xs={12}>
            {successNote && <Alert color="success">{successNote}</Alert>}

            <Switch>
              <Route
                path={`${match.path}/privacy`}
                render={() => (
                  <Privacy
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/meta`}
                render={() => (
                  <Meta
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/date`}
                render={() => (
                  <DateTime
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/how_to`}
                render={() => (
                  <Help
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/embed`}
                render={() => (
                  <Embed
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/cover`}
                render={() => (
                  <Cover
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/guests`}
                render={() => (
                  <Guests
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/changelink`}
                render={() => (
                  <EditLink
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/guestbook`}
                render={() => (
                  <Guestbook
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/share`}
                render={() => (
                  <Share
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/upload`}
                render={() => (
                  <Upload
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                  />
                )}
              />

              <Route
                path={`${match.path}/`}
                render={() => (
                  <Preview
                    event={event}
                    username={username}
                    broadcaster={broadcaster}
                    onPublishEvent={this.publishEvent}
                  />
                )}
              />
            </Switch>
          </Col>
        </Row>
      </>
    );
  }
}

export default EditEvent;
