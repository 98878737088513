import { API, graphqlOperation } from "aws-amplify";
import Icon from "components/Icon";
import { Field, Formik } from "formik";
import React from "react";
import {Helmet} from "react-helmet"
import { Redirect } from "react-router-dom";
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import * as Yup from "yup";

import eventFragment from "../fragment";

const AliasSchema = Yup.object().shape({
  alias: Yup.string()
    .required("Required")
    .nullable()
    .matches(
      /^[\d\w-_]+$/,
      "Only letters, numbers, dashes and underscore allowed."
    ),
});

const updateEventMutation = `
  mutation ($slug: String!, $alias: String!) {
    updateEventAlias(
      slug: $slug,
      alias: $alias
    ) {
      ${eventFragment}
    }
  }
`;

class EditLink extends React.Component {
  state = {
    done: false,
  };

  onSubmit = async (values, { setSubmitting }) => {
    const { alias } = values;
    const { slug } = this.props.event;
    
    try {
      if (!alias) throw new Error("Link required");

      const {
        errors,
        data: { updateEvent },
      } = await API.graphql(
        graphqlOperation(updateEventMutation, { slug, alias: alias.toLowerCase() })
      );

      if (!errors) {
        this.setState({ done: true });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: "Sorry, this link is not available.",
      });
    }

    setSubmitting(false);
  };

  render() {
    const { event } = this.props;
    const { done } = this.state;

    if (done) {
      return <Redirect to={`/event/${event.slug}`} />;
    }

    return (
      <Formik
        initialValues={{ ...event }}
        validationSchema={AliasSchema}
        onSubmit={this.onSubmit}
      >
        {({ values, submitForm, isSubmitting, isValid, errors, touched }) => (
          <Form>
            <Helmet>
              <title>Edit Link</title>
            </Helmet>
            <p>Choose a new link for your private event.</p>
            <p>
              <strong>
                Do not change your event link once you've shared it with your
                guests.
              </strong>
            </p>

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Icon name="link" /> evt.live/
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                name="alias"
                tag={Field}
                autoFocus
                placeholder="OurEventHashtag"
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="none"
                spellcheck="false"
                invalid={errors.alias && touched.alias}
              />
              {errors.alias && touched.alias && (
                <FormFeedback invalid={true}>{errors.alias}</FormFeedback>
              )}
            </InputGroup>

            {this.state.errorMessage && this.state.errorMessage.length > 0 && (
              <Alert color="danger" className="my-3">
                {this.state.errorMessage}
              </Alert>
            )}

            <Button
              color="primary"
              className="mt-3"
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
            >
              Check Availability and Update
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default EditLink;
