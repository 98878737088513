import { Auth } from "aws-amplify";
import Icon from "components/Icon";
import Logo from "components/Logo";
import React  from "react";
import { Link } from "react-router-dom";
import { NavLink as RRNavLink, withRouter } from "react-router-dom";
import styled from "styled-components";

import S from "./style";

const landingUrl = process.env.REACT_APP_LANDING_URL || "/";

const BareLink = styled(Link)`
  color: black;
  :hover {
    color: black;
    text-decoration: none;
  }
`;

class Header extends React.Component {
  state = { isOpen: false, loaded: false };

  componentDidMount = async () => {
    // attempt to load auth current user and pass email
    try {
      const user = await Auth.currentAuthenticatedUser();
      const { email, name } = user.attributes;
      
      const username = user.attributes["custom:broadcaster"];
      window.$crisp.push(["set", "user:nickname", [name]]);
      window.$crisp.push(["set", "user:email", email]);
      window.$crisp.push(["set", "session:data", [[["username", username]]]]);
    } catch (err) {
      console.error(err);
    }
  }

  // componentWillReceiveProps = () => {
  //   this.refetchProfile();
  // };

  // refetchProfile = async () => {
  //   try {
  //     const {
  //       data: { broadcaster },
  //     } = await API.graphql(
  //       graphqlOperation(
  //         `query { broadcaster: broadcasterProfile { username credits }  }` // myEvents { title }
  //       )
  //     );
  //     const { attributes } = await Auth.currentAuthenticatedUser({
  //       bypassCache: true,
  //     });
  //     const { username, credits } = broadcaster;
  //     const { email_verified } = attributes;
  //     this.setState({ username, credits, email_verified, loaded: true });
  //   } catch (error) {}
  // };

  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  signOut = () =>
    Auth.signOut()
      .then(() => (window.location = landingUrl))
      .catch((err) => console.error(err));

  render = () => {
    if (this.props.hideNav) {
      return null;
    }
    return (
      <>
        <S.Container>
          <S.NavigationBar color="light" light expand="md" fixed="top">
            <BareLink to="/">
              <Logo />
            </BareLink>

            <S.Navigation className="ml-auto navbar-right" navbar>
              <S.NavigationItem>
                <S.NavigationLink to={`/settings/profile`} tag={RRNavLink}>
                  <Icon name="user" />
                </S.NavigationLink>
              </S.NavigationItem>
            </S.Navigation>
          </S.NavigationBar>
        </S.Container>
      </>
    );
  };
}

export default withRouter(Header);
