import React from 'react';
import {FormFeedback, FormGroup, Input, InputGroup, Label} from "reactstrap";
import {ErrorMessage} from 'formik';
import styled from 'styled-components';

const FieldErrorTag = styled.div`
    margin-bottom: 10px;
    font-size: 11px;
    color: #CD1231;
    text-align: center;
`;

const FieldText = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  ...props
}) => (
  <FormGroup>
    {props.label && (
      <Label for={fields.name} className={"label-color"}>
        {props.label}
      </Label>
    )}
    <InputGroup>
      {props.addonPrepend ? props.addonPrepend() : null}
      <Input
        {...props}
        {...fields}
        invalid={Boolean(touched[fields.name] && errors[fields.name])}
        valid={Boolean(touched[fields.name] && !errors[fields.name])}
      />
      {props.addon ? props.addon() : null}
    </InputGroup>
    <ErrorMessage name={fields.name}>
      {(error) => {
        if (props.ErrorTag) return <props.ErrorTag>{error}</props.ErrorTag>;
        return <FieldErrorTag>{error}</FieldErrorTag>;
      }}
    </ErrorMessage>
  </FormGroup>
);
export default FieldText;
