import FormSubHeader from "components/FormSubHeader";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
const Embed = ({ event }) => {
  const textarea = useRef();
  const [embedStyle, setEmbedStyle] = useState(`embed`);

  const [autoplay, setAutoplay] = useState(true);

  const width = "100%";
  const height = 600; //includeGuestbook ? 435 : 546;

  const allowAutoplay = autoplay ? `allow="autoplay"`: ``;
  const autoPlayUrlParam = autoplay ? `` : `?autoplay=false`;

  const embedCodes = {
    embed: `<iframe src="https://evt.live/${event.broadcaster}/${event.slug}/embed${autoPlayUrlParam}" width="${width}" height="${height}" style="max-width:800px;margin:0 auto;" allowfullscreen ${allowAutoplay} allowtransparency="true" frameborder="0"></iframe>`,
    embedBare: `<div style="padding-bottom:56.25%;position:relative;margin:0 auto;"><iframe src="https://evt.live/${event.broadcaster}/${event.slug}/embedBare${autoPlayUrlParam}" style="width:100%;height:100%;position:absolute;left:0px;top:0px;" width="100%" height="100%" allowfullscreen ${allowAutoplay} allowtransparency="true" frameborder="0"></iframe>`,
  };


  return (
    <div>
      <FormSubHeader>Embed into a Website</FormSubHeader>
      <Helmet><title>Embed</title></Helmet>
      <p>
        Copy the following code to embed the stream into your site. Disable Autoplay
        if you're using multiple embeds on the same page.
      </p>

      <p>
        <label htmlFor="embedStyle">Embed Style</label>{" "}
        <select
          onChange={(evt) => setEmbedStyle(evt.target.value)}
          id="embedStyle"
        >
          <option value="embed">Full Embed</option>
          <option value="embedBare">Just Video</option>
        </select>
      </p>

      <p>
        <label htmlFor="allowAutoplay">Allow Video Autoplay</label>{" "}
        <input
          id="allowAutoplay"
          type="checkbox"
          checked={autoplay}
          onChange={(evt) => setAutoplay(evt.target.checked)}
        />{" "}
      </p>

      <textarea
        value={embedCodes[embedStyle]}
        style={{ width: "100%", height: 120 }}
        readOnly
        onClick={() => {
          textarea.current.select();
        }}
        ref={textarea}
      />

      <FormSubHeader className="mt-5">Embed Preview</FormSubHeader>

      <div dangerouslySetInnerHTML={{ __html: embedCodes[embedStyle] }}></div>
    </div>
  );
};

export default Embed;
