import { API, graphqlOperation } from 'aws-amplify';
import FieldText from 'components/FieldText'
import FormSubHeader from 'components/FormSubHeader'
import { Field, FieldArray, Formik } from "formik";
import React from 'react'
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom'
import {
  Button,
  Form,
} from 'reactstrap';
import * as Yup from 'yup';

import eventFragment from '../fragment'

const EventMeta = Yup.object().shape({
  title: Yup.string().required('Required'),
});

const updateEventMutation = `
  mutation UpdateEventMeta($slug: String!, $details: EventMetaInput!) {
    updateEventMeta(
      slug: $slug,
      details: $details
    ) {
      ${eventFragment}
    }
  }
`;


class Meta extends React.Component {
  state = {
    done: false,
  }

  onSubmit = async (values, { setSubmitting }) => {
    const {
      title,
      event_location,
      links
    } = values;

    const { slug } = this.props.event;
    const details = {
      title,
      event_location,
      links: (links || []).map(({ url, title }) => ({ url, title })),
    };

    const { errors, data: { updateEvent } } = await API.graphql(
      graphqlOperation(updateEventMutation, { slug, details })
    );

    if (!errors) {
      this.setState({ done: true })
    } else {
      this.setState({
        errorMessage: 'Could not create event. Please double check and try again.'
      })
    }

    setSubmitting(false);
  }

  render() {
    const { event } = this.props;
    const { done } = this.state;

    if (done) { return (<Redirect to={`/event/${event.slug}`} />); }

    return (
      <Formik
        initialValues={{ ...event }}
        validationSchema={EventMeta}
        onSubmit={this.onSubmit}
      >
        {({ values, submitForm, isSubmitting, isValid }) => (
          <Form>
            <Helmet>
              <title>Event Metadata</title>
            </Helmet>
            <Field
              label="Event Title"
              name="title"
              component={FieldText}
              placeholder="Title"
            />

            <Field
              label="Event Location"
              name="event_location"
              component={FieldText}
              placeholder="EventEvent Location (e.g. The Metropolitan Museum of Art, New York City"
            />

            <FormSubHeader className="mt-5">
              Registry / Website Links
            </FormSubHeader>

            <p>
              Add links to your event page. Link to your registry, website,
              program, PayPal, Cash App, Venmo.
            </p>

            <FieldArray
              name="links"
              render={(arrayHelpers) => (
                <div>
                  {values.links && values.links.length > 0 ? (
                    values.links.map((friend, index) => (
                      <div key={index}>
                        <Field
                          name={`links.${index}.title`}
                          component={FieldText}
                          placeholder="Title (i.e. Our Registry)"
                        />
                        <Field
                          name={`links.${index}.url`}
                          component={FieldText}
                          placeholder="Link (i.e. https://zola.com/our_registry)"
                        />
                        <div className="mb-4">
                          <Button
                            color="link"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            <i
                              className="icon fa fa-trash pr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            Remove Link
                          </Button>
                          <Button
                            color="link"
                            onClick={() =>
                              arrayHelpers.insert(index, { url: "", title: "" })
                            } // insert an empty string at a position
                          >
                            + Add another link
                          </Button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <Button
                      className="mb-3"
                      outline
                      onClick={() => arrayHelpers.push("")}
                    >
                      <i
                        className="icon fa fa-plus pr-2"
                        aria-hidden="true"
                      ></i>
                      Add a link to your event page
                    </Button>
                  )}
                </div>
              )}
            />

            <Button
              color="primary"
              className="mt-3"
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
            >
              Save Changes
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
};

export default Meta;
