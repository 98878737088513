import React from 'react';
import { Alert } from 'reactstrap';

import Header from "../../Authenticated/Header";

export default () => (
  <div className="unauthenticated p-3">
    <Header />
    <form className="form-signin text-center">
      <h1 className="h3 mb-3 font-weight-normal">Check your email</h1>

      <Alert color="info">
        We've sent you an email with a link to set a new password.
      </Alert>
    </form>
  </div>
);
