import Icon from "components/Icon";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom"
import {
  Button,
  Label
} from "reactstrap";

// const EventLink = ({ broadcaster, slug}) => (
class EventLink extends React.Component {
  state = {
    copied: false
  };

  onCopy = () => {
    this.setState({
      copied: true
    });
  };

  render() {
    const {
      broadcaster,
      slug,
      alias,
      linkTitle = "Your Event Link",
    } = this.props;
    const { copied } = this.state;
    const displayUrl = alias
      ? `https://evt.live/${alias}`
      : `https://evt.live/${broadcaster}/${slug}`;
    
    
    return (
      <>
        <div className="d-flex justify-content-between text-center">
          <Label>{linkTitle}</Label>
        </div>
        <div className="">
          <a href={displayUrl} style={{ color: "#0000EE" }} target="_blank">
            {displayUrl}
          </a>
          <CopyToClipboard text={displayUrl} onCopy={this.onCopy}>
            <Button color="link" style={{ color: "#0000EE" }}>
              {!copied && (
                <>
                  <Icon name="copy" />
                  <span className="d-sm-inline d-none">Copy</span>
                </>
              )}
              {copied && (
                <>
                  <Icon name="check" />
                  Copied!
                </>
              )}
            </Button>
          </CopyToClipboard>

        </div>
      </>
    );
  }
}

export default EventLink;
