import React from 'react';
import S from './style';

// wrapper to apply styles
export default (props) => {
  return (
    <S.DatePickerWrapper>
      <S.DatePicker {...props} />
    </S.DatePickerWrapper>
  );
}
