import { API, Auth, graphqlOperation } from "aws-amplify";
import FieldText from "components/FieldText";
import FormSubHeader from "components/FormSubHeader";
import { Field, Formik } from "formik";
import React from "react";
import { Alert, Button, Col, Form, Row } from "reactstrap";
import * as Yup from "yup";

const myQuery = `
  query MyProfile {
    broadcasterProfile {
      stream_key
      company_name
    }
  }
`;

const Broadcaster = Yup.object().shape({
  company_name: Yup.string().required("Required"),
});

const updateMutation = `
  mutation UpdateBroadcasterProfile($details: BroadcasterProfileInput!) {
    updateBroadcasterProfile(
      details: $details
    ) {
      stream_key
      company_name
    }
  }
`;

class Page extends React.Component {
  state = {};

  componentWillMount = async () => {
    const {
      data: { broadcasterProfile: broadcaster },
    } = await API.graphql(graphqlOperation(myQuery));
    this.setState({
      broadcaster,
    });
  };

  onSubmit = async (values, { setSubmitting }) => {
    const { company_name } = values;

    const details = { company_name };

    const {
      errors,
      data: { updateBroadcasterProfile },
    } = await API.graphql(graphqlOperation(updateMutation, { details }));

    if (!errors) {
      this.setState({ done: true });
    } else {
      this.setState({
        errorMessage:
          "Could not update profile. Please double check and try again.",
      });
    }

    setSubmitting(false);
  };

  render() {
    const { username, broadcaster, done } = this.state;

    if (!broadcaster) {
      return <FormSubHeader>Loading...</FormSubHeader>;
    }

    return (
      <Formik
        initialValues={{ ...broadcaster }}
        validationSchema={Broadcaster}
        onSubmit={this.onSubmit}
      >
        {({
          values,
          submitForm,
          isSubmitting,
          errors,
          touched,
          isValid,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            {done && <Alert color="success">Saved</Alert>}

            

            <FormSubHeader>Company Name</FormSubHeader>

            <Field
              label="Company Name"
              name="company_name"
              component={FieldText}
              placeholder="Company Name"
            />

            <Button
              color="primary"
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default Page;
