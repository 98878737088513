import { Auth } from "aws-amplify";
import React from "react";
import { Alert, FormText } from "reactstrap";

import Header from "../../Authenticated/Header"

export default class SetPassword extends React.Component {
  state = {
    password: "",
    loading: false,
    errorMessage: "",
  };

  doSetPassword = async (e) => {
    e.preventDefault();
    const dashboardUrl = "/";

    const { password } = this.state;
    const { location } = this.props;

    this.setState({ loading: true });

    const query = new URLSearchParams(location.search);

    const username =
      query.get("username") || window.localStorage.getItem("email");

    const temp = query.get("temp");
    const code = query.get("code");

    switch (true) {
      case !!username && !!temp: {
        return Auth.signIn(username.replace(" ", "+"), temp)
          .then((user) => Auth.completeNewPassword(user, password, {}))
          .then(() => {
            window.location = dashboardUrl;
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              errorMessage: err.message,
              loading: false,
            });
          });
      }
      case !!code: {
        return Auth.forgotPasswordSubmit(
          username.replace(" ", "+"),
          code,
          password
        )
          .then(() => Auth.signIn(username.replace(" ", "+"), password))
          .then(() => {
            window.location = dashboardUrl;
          })
          .catch((err) => {
            // Hide overlay
            // todo check "LimitExceededException"
            console.log(err);
            this.setState({
              errorMessage: err.message,
              loading: false,
            });
          });
      }
      default: {
        this.setState({ error: true, loading: false });
      }
    }
  };

  displayError = () => {
    if (this.state.errorMessage.length > 0) {
      return <Alert color="danger">{this.state.errorMessage}</Alert>;
    }

    return "";
  };

  setPass = (password) => {
    this.setState({
      password,
      error: false,
      // valid: /^(?=.*\d)(?=.*).{8,}$/.test(password),
      valid: /^.{8,}$/.test(password),
    });
  };

  render = () => (
    <div className="unauthenticated p-3">
      <Header />
      <form className="form-signin text-center" onSubmit={this.doSetPassword}>

        <h1 className="h3 mb-3 font-weight-normal">Choose New Password</h1>
        {this.displayError()}
        <label className="sr-only">New Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          required
          value={this.state.password}
          onChange={(e) => this.setPass(e.target.value)}
        />
        {!this.state.valid && (
          <FormText className="mt-3">Minimum 8 symbols.</FormText>
        )}
        <button
          className="btn btn-lg btn-primary btn-block my-3"
          type="submit"
          disabled={this.state.loading || !this.state.valid}
        >
          Set New Password
        </button>
      </form>
    </div>
  );
}
