import "react-dropzone-uploader/dist/styles.css";

import { API, graphqlOperation } from "aws-amplify";
import FormSubHeader from "components/FormSubHeader";
import Icon from 'components/Icon';
import React, { Fragment } from 'react'
import Dropzone from 'react-dropzone-uploader'
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom'
import { Button, Progress } from 'reactstrap';
import styled from 'styled-components';

import eventFragment from "../fragment";

const getColor = (props) => {
  if (props.isDragReject) {
      return '#c66';
  }
  if (props.isDragActive) {
      return '#6c6';
  }
  return '#CCC';
};

const Container = styled.div`
  min-height: 400px;
  border: 2px dashed  ${props => getColor(props)};
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  margin: 0 auto;
  color: #CCC;
  text-align: center;
`;

const Accepts = styled.small`
  display:block;
  margin-top: 5%;
  margin-bottom: 10%;
`;

const FileDropHeader = styled.h1`
  margin-top: 50px;
`;


const updateEventMutation = `
  mutation updateEventUploadStatus($slug: String!, $video_uploaded: Boolean!, $vod_filename: String, $notify_guests: Boolean) {
    updateEventUploadStatus(
      slug: $slug,
      video_uploaded: $video_uploaded,
      vod_filename: $vod_filename,
      notify_guests: $notify_guests
    ) {
      ${eventFragment}
    }
  }
`;


class Cover extends React.Component {
  state = {
  };

  componentWillMount() {
    this.setState({
      notify_guests: !!this.props.event && this.props.event.event_status === 'past'
    });
  }

  getUploadParams = (stream_key) => async ({ file, meta }) => {
    const  { name, type: contentType } = meta;
    // Lambda to get pre-signed upload url:
    const response = await fetch(
      `https://vauoabelw3.execute-api.us-east-1.amazonaws.com/prod/`,
      {
        method: "POST",
        body: JSON.stringify({
          stream_key,
          name: name.replace(/[^\.\w]/g, '-'),
          contentType
        }),
      }
    );
    const json = await response.json();

    console.log(json);
    
    const { url, key } = json;

    this.setState({ vod_filename: `s3://eventlivehls/${key}` });

    return {
      body: file,
      meta: { fileUrl: url },
      url,
      headers: {
        'x-amz-acl': 'public-read',
        'Cache-Control': 'max-age=31536000'
      },
      method: "PUT",
    };
  };

  onChangeStatus = async (fileWithMeta, status) => {
    console.log(fileWithMeta);
    console.log(status);
    const { slug } = this.props.event;
    const { vod_filename } = this.state;

    if (status === `done`) {
      const { errors } = await API.graphql(
        graphqlOperation(updateEventMutation, {
          slug,
          video_uploaded: true,
          vod_filename,
          notify_guests: this.state.notify_guests,
        })
      );

      if (!errors) {
        this.setState({ done: true });
      }
    }
  }

  renderExplanation = (isDragActive) => (
    <Fragment>
      {isDragActive ? (
        <FileDropHeader>Drop file here...</FileDropHeader>
      ) : (
        <FileDropHeader>Drop video file here</FileDropHeader>
      )}
      <p>Or...</p>
      <Button color="primary">Select file from your computer</Button>

      <Accepts>Accepts video files</Accepts>
    </Fragment>
  );

  render() {
    const { event  } = this.props;
    const { done, uploading, progress } = this.state;

    if (done) {
      return (
        <Redirect
          to={{
            pathname: `/event/${event.slug}`,
            state: {
              successNote:
                "Video have uploaded successfully! Please allow a few minutes to process the video.",
            },
          }}
        />
      );
    }

    return (
      <div>
        <FormSubHeader>Upload Video</FormSubHeader>
        <Helmet>
          <title>Upload Video</title>
        </Helmet>
        <p>
          Upload a slideshow, a higher quality or edited video for "
          {event.title}", please select the file below.
        </p>

        {this.props.event.event_status === "upcoming" && 
          <p>
            Once you go Live, the live stream video will replace the uploaded video automatically.
          </p>
        }

        {event.video_uploaded && (
          <p>
            Video Uploaded
            {event.vod_filename && !event.vod_converted && (
              <>
                <Icon name="spinner fa-spin" /> Processing Video
              </>
            )}
          </p>
        )}

        <p>
          <input
            type="checkbox"
            checked={this.state.notify_guests}
            onClick={() =>
              this.setState({
                notify_guests: !this.state.notify_guests,
              })
            }
          />{" "}
          Automatically notify the guests that the replay is available upon
          video processing
        </p>

        <Dropzone
          onChangeStatus={this.onChangeStatus}
          accept={"video/*"}
          getUploadParams={this.getUploadParams(event.stream_key)}
          multiple={false}
          inputWithFilesContent={"Change"}
          styles={{
            dropzone: {
              minHeight: 400,
              overflow: "auto",
            },
          }}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
            return (
              <Container
                isDragActive={isDragActive}
                isDragReject={isDragReject}
                {...getRootProps()}
              >
                <input {...getInputProps()} />

                {!uploading && this.renderExplanation(isDragActive)}

                {uploading && (
                  <div>
                    <FileDropHeader>
                      Please wait, uploading video{" "}
                      <Icon name="spinner fa-spin" />
                    </FileDropHeader>
                    <div style={{ margin: 10 }}>
                      <Progress animated value={progress} />
                    </div>
                  </div>
                )}
              </Container>
            );
          }}
        </Dropzone>
      </div>
    );
  }
};

export default Cover;
