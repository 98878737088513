import React from "react";

export default () => (
  <div className="text-center" style={{ maxWidth: 254, margin: '0 auto'}}>
    <a href="https://apps.apple.com/us/app/eventlive-event-live/id1457137042?mt=8">
      <img
        src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-05-16&kind=iossoftware&bubble=ios_apps"
        alt="Download on the App Store"
        width="220"
      />
    </a>
    <a href="https://play.google.com/store/apps/details?id=pro.eventlive&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
      <img
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        width="254"
      />
    </a>
  </div>
);


