import { Auth } from "aws-amplify";
import moment from "moment-timezone";

export const formatDate = (event, key = "event_datetime_utc") =>
  moment(event[key])
    .tz(event.event_timezone)
    .format("MMM Do YYYY, h:mm a z");

export const timeLeft = (event_datetime_utc) => {
  const now = moment();
  const then = moment(event_datetime_utc);

  const duration = moment.duration(now.diff(then));

  return duration > 0
    ? `${moment.duration(now.diff(then)).humanize()} ago`
    : `Starts in ${moment.duration(now.diff(then)).humanize()}`;
};

export const downloadGuestbook = (broadcaster, slug) => {
  window.location.href = `https://wz2qnpdl67.execute-api.us-east-1.amazonaws.com/prod?broadcaster=${broadcaster}&slug=${slug}`;
};

export const privacySettings = [
  // {
  //   value: "public",
  //   label: "Anyone can see this event on my broadcaster page",
  // },
  { value: "unlisted", label: "Only people with the link can watch" },
  { value: "password_protected", label: "Password protected" },
  { value: "unpublished", label: "Invisible (event appears as \"Not Found\")" },
];

export const bannedUsernames = [
  "admin",
  "broadcaster",
  "www",
  "webmail",
  "mail",
  "server",
  "remote",
  "blog",
  "ns1",
  "ns2",
  "smtp",
  "secure",
  "vpn",
  "admin",
  "app",
  "ftp",
  "shop",
  "portal",
  "test",
  "dev",
  "ns",
  "host",
  "web",
  "support",
  "forum",
  "community",
  "store",
  "mx1",
  "mx2",
  "api",
  "cdn",
  "exchange",
  "vps",
  "news",
  "m",
  "mailserver",
  "secure",
  "pop",
  "cloud",
  "promo",
  "free",
  "try",
];

export const checkIfUsernameExists = async (username) => {
  // Source of the code https://github.com/aws-amplify/amplify-js/issues/1067#issuecomment-436492775
  // Variable 'code' content is arbitrary
  const code = "000000";
  try {
    const data = await Auth.confirmSignUp(username, code, {
      // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
      forceAliasCreation: false,
    });
  } catch (err) {
    switch (err.code) {
      case "UserNotFoundException":
        return false;
      case "NotAuthorizedException":
        return true;
      case "AliasExistsException":
        // Email alias already exists
        return true;
      case "CodeMismatchException":
        return true;
      case "ExpiredCodeException":
        return true;
      default:
        return true;
    }
  }
};

export const usernameAvailabilityValidation = async (value) => {
  let usernameExists = await checkIfUsernameExists(value);
  if (usernameExists) {
    return "Username already exists.";
  }
};
